import React, { useEffect } from "react"
import { connect } from "react-redux"

import { formattedCurrency, saveOnDataLayer } from "../../utils";

import { ReactComponent as IconIA } from "../../assets/icon-header-ia.svg"
import { ReactComponent as IconIAService } from "../../assets/icon-ia-service.svg"
import { ReactComponent as IconVirtualAssistantPlan } from "../../assets/virtual-assistant-plan.svg"
import { PRONTUARIO_PRO_MONTHLY_UPSELL, PRONTUARIO_PRO_YEARLY_UPSELL, PRONTUARIO_PRO_YEARLY_UPSELL_12X } from "../../constants";

import './VirtualAssistantPlanCard.scss'
const VirtualAssistantLegacyPlanCard = (props) => {

    const {
        features,
        history,
        clinic
    } = props

    const productToUpsell = features.upsellPro ? "prontuario-pro" : "assistente-vetsmart"

    useEffect(() => {
        if (clinic?.isLoaded && clinic?.object) {
            saveOnDataLayer({
                event_category: "view_element",
                event_action: `${productToUpsell}:faca-upgrade`,
                event_label: "plano-cobranca"
            })
        }
    }, [ clinic ])

    return (
        <div id="va-plan-card" className="vsbox mb-3">
            <div className="row g-0 flex-column flex-sm-row">
                <div className="col g-0 p-3">
                    <div className="va-plan-card-title">
                        Assistente Vetsmart
                        <IconIA className="ms-2" width="24" height="24" fill="#FF8A00" />
                    </div>
                    <div className="feature-text">
                        <IconIA className="me-2" width="20" height="20" fill="#FF8A00" />
                        Prontuário PRO + Assistente Vetsmart | Cobrança {features?.planCode == 'yearly' ? 'anual' : 'mensal'}
                    </div>
                    <div className="d-flex feature-text mb-3">
                        <IconIAService className="d-block me-2" width="20" height="20" fill="var(--vsprontuario-primary-color)" />
                        Seus atendimentos ainda mais ágeis e eficientes
                    </div>
                    <p className="virtual-assistant-intro-text">Convidamos você, assinante do Prontuário PRO, a continuar utilizando o Assistente Vetsmart. Para manter essa funcionalidade, faça o upgrade para o novo plano PRO que inclui o Assistente. Com ele, você terá suporte digital para agilizar seus atendimentos, criando resumos clínicos rápidos e possibilidades de diagnósticos diferenciais.</p>
                    <p className="virtual-assistant-intro-text">Leve seu plano para um novo nível de eficiência!</p>
                </div>
                <div className="col-auto mt-0 d-flex">
                    <div className="va-plan-card-action-part p-4 d-flex flex-column align-items-center justify-content-between w-100">
                        <IconVirtualAssistantPlan className="mb-3" />
                        {features?.planCode == 'yearly' ? 
                        <div className="plan-price">Por apenas<br/><span className="cost">+{ formattedCurrency(PRONTUARIO_PRO_YEARLY_UPSELL, "R$", 0) }</span> à vista<br/>ou em até 12x de { formattedCurrency(PRONTUARIO_PRO_YEARLY_UPSELL_12X, "R$", 0) }</div> :
                        <div className="plan-price">Por apenas<br/><span className="cost">+{ formattedCurrency(PRONTUARIO_PRO_MONTHLY_UPSELL, "R$", 0) }</span> /mês</div>}
                        <button className="btn-submit btn-square text-normal mt-2 w-100" onClick={() => {
                            saveOnDataLayer({
                                event_action: `${productToUpsell}:botao:faca-upgrade`,
                                event_label: "plano-cobranca"
                            })
                            history.push('/configuracao-clinica/plano-e-cobranca/upgrade')
                        }}>
                            Faça o Upgrade
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        features: state.features,
        clinic: state.clinic,
        permissions: state.permissions
    }
}

export default connect(mapStateToProps)(VirtualAssistantLegacyPlanCard)
import React, { useState } from "react"
import { connect } from "react-redux"

import { formattedCurrency, redirectToCheckout, saveOnTracker, saveTrackersProSubscription } from "../../utils";

import VSDrawer from "../../components/vsDrawer/VSDrawer";

import { ReactComponent as IconVirtualAssistantSelect } from "../../assets/virtual-assistant-select.svg"
import { ReactComponent as IconVirtualAssistantDiagnosis } from "../../assets/icon-virtual-assistant-diagnosis.svg"
import { ReactComponent as IconVirtualAssistantHistoric } from "../../assets/icon-virtual-assistant-historic.svg"
import { ReactComponent as IconIA } from "../../assets/icon-header-ia.svg"
import { ReactComponent as IconIAService } from "../../assets/icon-ia-service.svg"

import './VirtualAssistantFreeOverlay.scss'
import { PRONTUARIO_PRO_MONTHLY_UPSELL, PRONTUARIO_PRO_YEARLY_UPSELL, PRONTUARIO_PRO_YEARLY_UPSELL_12X } from "../../constants";
const VirtualAssistantLegacyOverlay = (props) => {

    const {
        features,
        history,
        clinic
    } = props

    // const {
    //     assistant
    // } = useParams()

    return (
        <VSDrawer
            id="virtual-assistant-free-overlay"
            // width="40%"
            width="385px"
            title={
                <div className="row">
                    <div className="col-12">
                        <IconIA className="me-2" width="24" height="24" fill="#FF8A00" />
                        Assistente Vetsmart
                    </div>
                </div>
            }
            onCancel={() => history.goBack()}
            onClose={() => history.goBack()}
            VSDrawerFooterProps={{
                show: true,
                children: (
                    <div className="row">
                        <div className="col-12">
                            {features?.planCode == 'yearly' ? 
                            <div className="va-footer-text">Por apenas <b>+ { formattedCurrency(PRONTUARIO_PRO_YEARLY_UPSELL, "R$", 0) }</b> à vista<br/>ou em até <b>12x de { formattedCurrency(PRONTUARIO_PRO_YEARLY_UPSELL_12X, "R$", 0) }</b></div> :
                            <div className="va-footer-text">Por apenas <b>+ { formattedCurrency(PRONTUARIO_PRO_MONTHLY_UPSELL, "R$", 0) }</b>/mês</div>}
                        </div>
                        <div className="col-12">
                            {clinic.isOwner &&
                            <button className="btn-submit btn-square text-normal mt-2 w-100" onClick={() => {
                                // saveTrackersProSubscription(features)
                                // redirectToCheckout(history)
                                history.push('/configuracao-clinica/plano-e-cobranca/upgrade')
                            }}>
                                Faça o Upgrade
                            </button>}
                        </div>
                    </div>
                )
            }}
        >
            <div className="text-center mx-auto mb-2">
                <IconVirtualAssistantSelect className="mb-4" />
                <h3 className="virtual-assistant-free-overlay-title">Assistente Vetsmart</h3>
            </div>
            <div className="row justify-content-center mb-4">
                <div className="col-auto">
                    <div className="feature-text">
                        <IconIA className="me-2" width="20" height="20" fill="#FF8A00" />
                        Prontuário PRO + Assistente Vetsmart
                    </div>
                    <div className="d-flex feature-text">
                        <IconIAService className="d-block me-2" width="20" height="20" fill="var(--vsprontuario-primary-color)" />
                        Seus atendimentos ainda mais ágeis e eficientes
                    </div>
                </div>
            </div>
            <p className="virtual-assistant-intro-text">Convidamos você, assinante PRO, a continuar utilizando o Assistente Vetsmart. Para manter essa funcionalidade, <b>faça o upgrade para o novo plano PRO que inclui o Assistente</b>. Com ele, você terá suporte digital para agilizar seus atendimentos, criando resumos clínicos rápidos e recebendo sugestões de diagnósticos.</p>
            <p className="virtual-assistant-intro-text">Leve seu plano para um novo nível de eficiência!</p>
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        features: state.features,
        clinic: state.clinic,
        permissions: state.permissions
    }
}

export default connect(mapStateToProps)(VirtualAssistantLegacyOverlay)
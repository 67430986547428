import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { connect } from "react-redux"

import { Autocomplete } from "@mui/lab"
import { Popover } from "@mui/material"

import { ReactComponent as IconTrash } from "../../../assets/icon-primary-trash.svg"
import { ReactComponent as IconCopy } from "../../../assets/icon-copy.svg"
import { ReactComponent as IconPlus } from "../../../assets/icon-plus.svg"

import { timeOptions } from "../../calendar/Calendar"
import { validateTime } from "../../../utils"
import { ACL } from "../../../constants"

import TextFieldMask from "../../../components/textFieldMask/TextFieldMask"
import VSCheckbox from "../../../components/vsCheckbox/VSCheckbox"

export const weekDays = [
    {
        nick: "Dom",
        label: "Domingo",
        value: 1
    },
    {
        nick: "Seg",
        label: "Segunda-feira",
        value: 2
    },
    {
        nick: "Ter",
        label: "Terça-feira",
        value: 3
    },
    {
        nick: "Qua",
        label: "Quarta-feira",
        value: 4
    },
    {
        nick: "Qui",
        label: "Quinta-feira",
        value: 5
    },
    {
        nick: "Sex",
        label: "Sexta-feira",
        value: 6
    },
    {
        nick: "Sab",
        label: "Sábado",
        value: 7
    }
]

import "./WeekHourField.scss"
const WeekHourField = forwardRef((props, ref) => {

    const {
        clinic,
        permissions,
        workHours,
        onChange
    } = props

    const defaultTime = {
        startTime: "",
        endTime: "",
        errorTime: false,
        helperTime: ""
    }

    const defaultWeekHours = weekDays.map(weekDay => ({
        ...weekDay,
        checked: false,
        times: [
            {...defaultTime}
        ]
    }))

    const [ weekHours, setWeekHours ] = useState(defaultWeekHours)
    const [ copy, setCopy ] = useState({})
    const [ copyTo, setCopyTo ] = useState([])
    
    useImperativeHandle(ref, () => ({
        validate: validate,
        fieldsWithErrorText: fieldsWithErrorText,
        getTimes: getTimes
    }));

    const validate = (isValid) => {
        const newWeekHours = [...weekHours]
        weekHours.some((week, index) => {
            week.times?.map((time, indexTime) => {
                if (week.checked) {
                    newWeekHours[index].errorWeek = false
                    newWeekHours[index].helperWeek = ""

                    if (validateTime(time.startTime) && validateTime(time.endTime)) {
                        const start = parseInt(time.startTime.replace(":", ""))
                        const end = parseInt(time.endTime.replace(":", ""))
                        const prevEnd = parseInt(week.times[indexTime - 1]?.endTime?.replace(":", "")) || start
                        if (end < start) {
                            isValid = false
                            newWeekHours[index].times[indexTime].errorTime = true
                            newWeekHours[index].times[indexTime].helperTime = "O horário de término não pode ser antes do início"
                            return
                        } else if (start < prevEnd) {
                            isValid = false
                            newWeekHours[index].times[indexTime].errorTime = true
                            newWeekHours[index].times[indexTime].helperTime = "O horário de início não pode ser menor que o horário de término anterior"
                        } else {
                            newWeekHours[index].times[indexTime].errorTime = false
                            newWeekHours[index].times[indexTime].helperTime = ""
                        }
                    } else {
                        isValid = false
                        newWeekHours[index].times[indexTime].errorTime = true
                        newWeekHours[index].times[indexTime].helperTime = "Os horários são obrigatórios quando selecionados"
                        return
                    }
                } else {
                    newWeekHours[index].times[indexTime].errorTime = false
                    newWeekHours[index].times[indexTime].helperTime = ""

                    if (time.startTime?.length > 0 || time.endTime?.length > 0) {
                        isValid = false
                        newWeekHours[index].errorWeek = true
                        newWeekHours[index].helperWeek = `${week.label} não está selecionado, mas está com o horário preenchido`
                        return
                    } else {
                        newWeekHours[index].errorWeek = false
                        newWeekHours[index].helperWeek = ""
                    }
                }
            })
        })

        setWeekHours(newWeekHours)

        return isValid
    }
 
    const fieldsWithErrorText = (errors) => {
        weekHours.map(week => {
            if (week.errorWeek) {
                errors.push(week.label)
            }
            week.times.map(time => {
                if (time.errorTime) {
                    errors.push(`Horário de ${week.label}`)
                }
            })
        })
        return errors
    }

    const getTimes = () => {
        const times = {}
        weekHours.map(week => {
            if (week.checked) {
                times[week.value] = week.times.map(time => ({
                    startTime: time.startTime,
                    endTime: time.endTime
                }))
            }
        })
        return times
    }

    const setTime = (weekDayIndex, timeIndex, type, value) => {
        const newWeekHours = [...weekHours]
        newWeekHours[weekDayIndex].times[timeIndex][type] = value
        setWeekHours(newWeekHours)
    }

    useEffect(() => {
        onChange?.()
    }, [ 
        JSON.stringify(weekHours.map(week => ({
            checked: week.checked,
            error: week.errorWeek,
            times: week.times?.map(time => ({
                startTime: time.startTime,
                endTime: time.endTime,
                error: time.errorTime
            }))
        })))
    ])

    useEffect(() => {
        const workArray = Object.keys(workHours || {})
        if (workArray?.length > 0) {
            const newWeekHours = [...weekHours]
            workArray.map((weekValue, index) => {
                const weekIndex = weekHours.findIndex(value => value.value == parseInt(weekValue))
                newWeekHours[weekIndex].checked = true
                workHours[weekValue].map((time, indexTime) => {
                    newWeekHours[weekIndex].times[indexTime] = {
                        ...defaultTime,
                        startTime: time.startTime,
                        endTime: time.endTime
                    }
                })
            })
            setWeekHours(newWeekHours)
        }
    }, [ workHours ])

    return (
        <div id="weekhour">
            { weekHours.map((weekDay, index) => (
                <div key={index} className="row g-3 mb-3">
                    <div className="col col-md-3 col-lg-2 order-1">
                        {(permissions?.check(ACL.ADMIN) || clinic?.isOwner) ?
                        <VSCheckbox
                            label={weekDay.nick}
                            checked={weekDay.checked}
                            onChange={(checked) => {
                                const newWeekHours = [...weekHours]
                                newWeekHours[index].checked = checked
                                if (checked && newWeekHours[index].times.length == 0) {
                                    newWeekHours[index].times.push({ ...defaultTime })
                                }
                                setWeekHours(newWeekHours)
                            }}
                            className="weekhour-checkbox"
                        /> : <div className="weekhour-text d-flex align-items-center h-100">{weekDay.nick}</div>}
                    </div>
                    <div className="col-12 col-md col-xl-4 order-3 order-md-2">
                        { weekDay.times?.length > 0 ? (
                            weekDay.times?.map((time, indexTime) => (
                                <div key={indexTime} className={`row g-3 ${ indexTime != 0 ? "mt-1" : "" }`}>
                                    <div className="col">
                                        <Autocomplete
                                            value={time.startTime}
                                            options={timeOptions}
                                            onChange={(_, selected) => setTime(index, indexTime, "startTime", selected)}
                                            onInputChange={(event) => setTime(index, indexTime, "startTime", event?.target?.value || time.startTime)}
                                            renderInput={(params) => (
                                                <TextFieldMask
                                                    {...params}
                                                    type="tel"
                                                    mask={"00:00"}
                                                    placeholder="00:00"
                                                    label="Início às"
                                                    error={time.errorTime}
                                                    helperText={null}
                                                    className="weekhour-input"
                                                    size="small"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                            disabled={!permissions?.check(ACL.ADMIN) && !clinic?.isOwner}
                                            disableClearable
                                            openOnFocus
                                            freeSolo
                                            />
                                    </div>
                                    <div className="col">
                                        <Autocomplete
                                            value={time.endTime}
                                            options={timeOptions}
                                            onChange={(_, selected) => setTime(index, indexTime, "endTime", selected)}
                                            onInputChange={(event) => setTime(index, indexTime, "endTime", event?.target?.value || time.endTime)}
                                            renderInput={(params) => (
                                                <TextFieldMask
                                                    {...params}
                                                    type="tel"
                                                    mask={"00:00"}
                                                    placeholder="00:00"
                                                    label="Término às"
                                                    error={time.errorTime}
                                                    helperText={null}
                                                    className="weekhour-input"
                                                    size="small"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                            disabled={!permissions?.check(ACL.ADMIN) && !clinic?.isOwner}
                                            disableClearable
                                            openOnFocus
                                            freeSolo
                                        />
                                    </div>
                                    {(permissions?.check(ACL.ADMIN) || clinic?.isOwner) &&
                                    <div className="col-auto">
                                        <button
                                            className="vsbox-btn vsbox-btn-alert"
                                            onClick={() => {
                                                const newWeekHours = [...weekHours]
                                                newWeekHours[index].times.splice(indexTime, 1)
                                                if (newWeekHours[index].times.length == 0) {
                                                    newWeekHours[index].checked = false
                                                }
                                                setWeekHours(newWeekHours)
                                            }}
                                        >
                                            <IconTrash width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                                        </button>
                                    </div>}
                                    { time.errorTime && (
                                        <div className="col-12">
                                            <small className="text-danger">{ time.helperTime }</small>
                                        </div>
                                    )}
                                </div>
                            )) 
                        ) : (
                            <div className="row mb-3">
                                <div className="col-12">
                                    <div className="weekhour-unavailable">
                                        Indisponível
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {(permissions?.check(ACL.ADMIN) || clinic?.isOwner) &&
                    <div className="col-auto col-md-auto col-xl order-2 order-md-3">
                        <div className="row g-3 justify-content-end">
                            <div className="col-auto">
                                <button
                                    className="vsbox-btn vsbox-btn-secondary"
                                    onClick={(e) => {
                                        setCopy({
                                            index: index,
                                            element: e.target.parentElement
                                        })
                                    }}
                                >
                                    <IconCopy width="24" height="24" fill="var(--vsprontuario-secondary-color)" />
                                </button>
                                <Popover
                                    id="weekhour-copy"
                                    open={copy?.index == index}
                                    onClose={() => setCopy({})}
                                    anchorEl={copy?.element}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >
                                    <div className="vsbox">
                                        <div className="vsbox-body mb-0">
                                            <div className="row g-2">
                                                <div className="col-12">
                                                    Copiar horário para...
                                                </div>
                                                { weekDays.map((weekDay, copyIndex) => (
                                                    <div key={copyIndex} className="col-12">
                                                        <VSCheckbox
                                                            label={weekDay.label}
                                                            checked={copyTo.find(value => value == weekDay.value) || copyIndex == index}
                                                            position="start"
                                                            disabled={copyIndex == index}
                                                            onChange={(checked) => {
                                                                const newCopyTo = [...copyTo]
                                                                if (checked) {
                                                                    newCopyTo.push(weekDay.value)
                                                                } else {
                                                                    const removeIndex = newCopyTo.findIndex(value => value == weekDay.value)
                                                                    newCopyTo.splice(removeIndex, 1)
                                                                }
                                                                setCopyTo(newCopyTo)
                                                            }}
                                                            className="weekhour-copy-checkbox"
                                                        />
                                                    </div>
                                                )) }
                                                <div className="col-12">
                                                    <div className="row g-3">
                                                        <div className="col-auto">
                                                            <button
                                                                className={`btn-link`}
                                                                onClick={() => {
                                                                    setCopy({})
                                                                }}
                                                            >
                                                                Cancelar
                                                            </button>
                                                        </div>
                                                        <div className="col">
                                                            <button
                                                                className={`btn-submit btn-square w-100`}
                                                                onClick={() => {
                                                                    const newWeekHours = [...weekHours]
                                                                    copyTo.map(weekDayValue => {
                                                                        const copyIndex = weekHours.findIndex(weekDay => weekDayValue == weekDay.value)
                                                                        newWeekHours[copyIndex].checked = true
                                                                        newWeekHours[copyIndex].times = [...weekHours[copy.index].times]
                                                                    })
                                                                    setWeekHours(newWeekHours)
                                                                    setCopy({})
                                                                    setCopyTo([])
                                                                }}
                                                            >
                                                                Aplicar
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Popover>
                            </div>
                            <div className="col-auto">
                                <button
                                    className="vsbox-btn vsbox-btn-primary"
                                    onClick={() => {
                                        const newWeekHours = [...weekHours]
                                        newWeekHours[index].times.push({ ...defaultTime })
                                        setWeekHours(newWeekHours)
                                    }}
                                >
                                    <IconPlus width="24" height="24" className="me-0" fill="var(--vsprontuario-primary-color)" />
                                    <span className="d-none d-lg-inline-block">Adicionar intervalo</span>
                                </button>
                            </div>
                        </div>
                    </div>}
                    { weekDay.errorWeek && (
                        <div className="col-12 order-4">
                            <small className="text-danger">{ weekDay.helperWeek }</small>
                        </div>
                    )}
                    <hr className="mt-3" />
                </div>
            ))}
        </div>
    )
})

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(WeekHourField)
import React, { useEffect, useState } from 'react'

import './ReturnComplete.scss'
import ReturnList from './ReturnList'

import { ReactComponent as IconArrowLeft } from '../../assets/icon-arrow-left.svg'

import Tabs from '@mui/material/Tabs';
import Tab, { tabClasses } from '@mui/material/Tab';
import MenuItem from '@mui/material/MenuItem';
import FormControl, { formControlClasses } from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { styled } from '@mui/styles';
import { dayjs } from '../../utils';

const TabSimple = styled(({ className, ...props }) => (
    <Tab {...props} classes={{ root: className }} />
))(({ theme }) => ({
    textTransform: 'none !important',
    color: '#000000 !important',
    fontStyle: 'normal !important',
    fontWeight: '600 !important',
    fontSize: '12px !important',
    lineHeight: '16px !important',
    textTransform: 'uppercase !important',
    textAlign: 'center !important',
    minWidth: 'auto !important',
    maxWidth: '564px !important',
    padding: '12px 12px 0 12px !important'
}))

const FormControlSelect = styled(({ className, ...props }) => (
    <FormControl {...props} classes={{ root: className }} />
))(({ theme }) => !console.log(formControlClasses) && ({
    minWidth: '100px !important',
    textAlign: 'center !important',
    marginBottom: '1px !important'
}))

const ReturnComplete = (props) => {

    const {
        history
    } = props

    const defaultDate = dayjs()
    const [ selectedMonth, setSelectedMonth ] = useState(defaultDate.format("MM"))
    const [ selectedYear, setSelectedYear ] = useState(defaultDate.format("YYYY"))
    const [ dateFrom, setDateFrom ] = useState(defaultDate.clone().startOf('month'))
    const [ dateTo, setDateTo ] = useState(defaultDate.clone().endOf('month'))

    const months = Array.from(Array(15).keys()).map((_, index) => {
        return defaultDate.clone().add(index - 10, 'years').format("YYYY")
    })

    useEffect(() => {
        let date = dayjs(`01/${selectedMonth}/${selectedYear}`, "DD/MM/YYYY")
        if (date.isValid()) {
            setDateFrom(date.clone().startOf('month'))
            setDateTo(date.clone().endOf('month'))
        }
    }, [ selectedMonth, selectedYear ])

    return (
        <div id="history-complete" className="row">
            <div className="col-12">
                <div className="page-title">
                    Todos os retornos
                </div>
                <small className="page-subtitle" onClick={() => history.push('/retorno')}>
                    <IconArrowLeft width="8" height="12" /> Voltar para retornos da semana
                </small>
            </div>
            <div className="col-12">
                <div className="page-content">
                    <div className="row align-items-end separator mb-3">
                        <div className="col text-right">
                            <FormControlSelect>
                                <Select
                                    value={selectedYear}
                                    onChange={({ target : { value } }) => setSelectedYear(value)}
                                >
                                    { months.map(month => (
                                        <MenuItem value={month}>{ month }</MenuItem>
                                    )) }
                                </Select>
                            </FormControlSelect>
                        </div>
                        <div className="col-auto">
                            <Tabs
                                value={selectedMonth}
                                onChange={(_, selected) => setSelectedMonth(selected)}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                <TabSimple value={"01"} label="Jan" />
                                <TabSimple value={"02"} label="Fev" />
                                <TabSimple value={"03"} label="Mar" />
                                <TabSimple value={"04"} label="Abr" />
                                <TabSimple value={"05"} label="Mai" />
                                <TabSimple value={"06"} label="Jun" />
                                <TabSimple value={"07"} label="Jul" />
                                <TabSimple value={"08"} label="Ago" />
                                <TabSimple value={"09"} label="Set" />
                                <TabSimple value={"10"} label="Out" />
                                <TabSimple value={"11"} label="Nov" />
                                <TabSimple value={"12"} label="Dez" />
                            </Tabs>
                        </div>
                        <div className="col"></div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <ReturnList
                                history={history}
                                dateFrom={dateFrom.toDate()}
                                dateTo={dateTo.toDate()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReturnComplete
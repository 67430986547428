import React from 'react'

import PatientPhoto from '../../components/patientPhoto/PatientPhoto';

import { getAgeFromPatient } from '../patient/Patient';
import VSAccordionSimple from '../../components/vsAccordion/VSAccordionSimple';

import "./BoxPatient.scss"
const BoxPatient = (props) => {

    const {
        patient,
        history,
        actions,
        appendContent
    } = props

    const owner  = patient.get("owner") || {}
    const deceased = patient.get("deceased") == 1

    return (
        <div id="box-patient" className="vsbox">
            <div className="vsbox-body pb-1">
                <div className="row align-items-md-center align-items-start flex-md-row flex-column">
                    <div className="col">
                        <div className={`vsbox-profile ${deceased ? "patient-deceased" : ""}`}>
                            <div className="row align-items-center">
                                <div className="col-auto mb-md-2 mb-lg-0">
                                    <PatientPhoto patient={patient} withBorder />
                                </div>
                                <div className="col px-0 mb-md-2 mb-lg-0">
                                    <div
                                        className="vsbox-name vsbox-link"
                                        onClick={() => history.push(`/animal/${ patient.id }`)}
                                    >
                                        { patient.get("name") }
                                        { patient.get("deceased") == 1 && <> | <span className="text-danger">ÓBITO</span></>}
                                    </div>
                                    <div className="vsbox-data-light">
                                        <b>ID: { patient.get("patientNumberId") }</b>
                                        { " - "}
                                        { patient.get("breed") || "Raça não informada" }
                                        { patient.get("birthdate") && patient.get("deceased") != 1 && ` - `}
                                        { patient.get("deceased") != 1 && getAgeFromPatient(patient, true) }
                                    </div>
                                    <div className="vsbox-data-light">
                                        <b>Tutor(a):</b> { patient.get("owner").get("name") }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {actions &&
                    <div className="col-auto mt-3 mt-md-0 align-self-stretch align-self-md-center">
                        <div className="row align-items-center">
                            { actions }
                        </div>
                    </div>}
                </div>
                {appendContent && appendContent(patient)}
                <div className="vsbox-separator mt-3"></div>
                <div className="row">
                    <div className="col-12 owner-info">
                        <VSAccordionSimple
                            header={(expanded) => (
                                `${ expanded ? "Ocultar" : "Ver" } Informações do(a) Tutor(a)`
                            )}
                        >
                            <div className="row mb-2">
                                <div className="col-12 col-lg-auto">
                                    <label className="vsbox-info">
                                        Nome
                                    </label>
                                    <div className="vsbox-info-text">
                                        { owner.get("name") }
                                    </div>
                                </div>
                                <div className="col-12 col-lg-auto">
                                    <label className="vsbox-info">
                                        CPF
                                    </label>
                                    <div className="vsbox-info-text">
                                        { owner.get("cpf") || "Não informado" }
                                    </div>
                                </div>
                                <div className="col-6 col-lg-auto">
                                    <label className="vsbox-info">
                                        Celular
                                    </label>
                                    <div className="vsbox-info-text">
                                        { owner.get("phone") || "Não informado" }
                                    </div>
                                </div>
                                <div className="col-6 col-lg-auto">
                                    <label className="vsbox-info">
                                        Telefone
                                    </label>
                                    <div className="vsbox-info-text">
                                        { owner.get("landlinePhone") || "Não informado" }
                                    </div>
                                </div>
                            </div>
                        </VSAccordionSimple>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BoxPatient

import { useHistory } from "react-router-dom/cjs/react-router-dom"
import { connect } from "react-redux"

import { ReactComponent as IconCheck } from "../../assets/icon-primary-check.svg"
import { ReactComponent as IconQuestionO } from "../../assets/icon-question-o.svg"

import { getTrialEndsAtForProduct, redirectToCheckout, saveTrackersProSubscription } from "../../utils"

import "./Pro.scss"
const Pro = (props) => {

    const {
        features,
        faq,
        name,
        title,
        clinic,
        textArray,
        image
    } = props

    const faqFallback = "https://pl-vetsmart.zendesk.com/hc/pt-br/articles/17010537517211-Prontu%C3%A1rio-Pro"

    const history = useHistory()

    return (
        <div id="pro">
            {!clinic.isOwner &&
            <div className="col-12 mb-3">
                <div className="page-warning">Entre em contato com o <b>Dono da Clínica</b> para contratar os serviços do Prontuário PRO.</div>
            </div>}
            <div className="vsbox">
                <div className="vsbox-body">
                    <div className="row g-3 mb-4 pro-container">
                        <div className="col-12 col-md">
                            <div className="row g-3">
                                <div className="col-12">
                                    <span className="badge rounded-pill bg-secondary">Prontuário PRO</span>
                                </div>
                                <div className="col-12 mt-5">
                                    <div className="title">
                                        { title }
                                    </div>
                                </div>
                                <div className="col-12 mt-4">
                                    { textArray.map((text, index) => (
                                        <div className="row mb-3" key={index}>
                                            <div className="col-auto pe-0">
                                                <IconCheck width={24} height={24} fill="var(--vsprontuario-primary-color)" />
                                            </div>
                                            <div className="col">
                                                { text }
                                            </div>
                                        </div>
                                    )) }
                                </div>
                                <div className="col-12">
                                    { clinic.isOwner && (
                                        <button
                                            className="vsbox-btn vsbox-btn-primary px-5"
                                            onClick={() => {
                                                saveTrackersProSubscription(features)
                                                redirectToCheckout(history)
                                            }}
                                        >
                                            { getTrialEndsAtForProduct(features) ? (
                                                "Assinar o Prontuário PRO"
                                            ) : (
                                                "Experimentar 15 dias grátis"
                                            )}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-auto">
                            <div className="image mt-3">
                                { image }
                            </div>
                        </div>
                    </div>
                    <div className="row g-3">
                        <div className="col-12 info">
                            <div className="row g-3 justify-content-start justify-content-md-center">
                                <div className="col-auto pe-0">
                                    <IconQuestionO width={24} height={24} fill="var(--vsprontuario-primary-color)" />
                                </div>
                                <div className="col col-md-auto ps-2 text-start action-text">
                                    { faq && name ? (
                                        <>Ficou com dúvidas? Saiba como funciona a parte de <a href={faq || faqFallback} target="_blank">{ name }</a>.</>
                                    ) : (
                                        <>Ficou com dúvidas? Saiba mais sobre o <a href={faq || faqFallback} target="_blank">Prontuário PRO</a>.</>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        features: state.features,
        clinic: state.clinic,
        user: state.user,
    }
}

export default connect(mapStateToProps)(Pro)
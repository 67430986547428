import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';

import { ReactComponent as IconPrimaryPaw } from "../../../../assets/icon-primary-paw.svg"

import VetView from '../../../vet/VetView';
import VSList from '../../../../components/vsList/VSList';
import VSEmpty from '../../../../components/vsEmpty/VSEmpty';

import { getTrialEndsAtForProduct, redirectToCheckout, saveTrackersProSubscription } from '../../../../utils';
import { searchProfiles } from '../../../vet/Vet';
import { ACL } from '../../../../constants';

import './ClinicSettingUserManagement.scss'
const ClinicSettingUserManagement = props => {
    const {
        features,
        clinic,
        history,
        permissions
    } = props

    const filter = useRef(null)

    const rowsPerPage = 50

    const [ profiles, setProfiles ] = useState([])
    const [ status, setStatus ] = useState("0")

    const getClinicUsers = (searchBy, dateFrom, dateTo, orderBy, page) => {
        return new Promise((resolve, reject) => {
            const query = new Parse.Query("ClinicUser")
            query.equalTo("clinic", clinic.object);
            query.equalTo("isDeleted", false);
            if (status == "1") {
                query.equalTo("accepted", true)
            } else if (status == "2") {
                query.equalTo("accepted", true)
            } else if (status == "3") {
                query.doesNotExist("accepted")
            }
            if (profiles.length > 0) {
                query.containedIn("profile", profiles.map(({ value }) => value))
            }
            query.withCount()
            query.skip((page - 1) * rowsPerPage)
            query.limit(rowsPerPage)
            query.include("profile");
            query.include("user");
            query.ascending("createdAt");
            query.addAscending("objectId")
            query.find()
            .then(data => {
                const {
                    results,
                    count
                } = data

                let total = count
                let filteredResults = results.filter(result => {
                    if (searchBy) {
                        if (result.get("name")?.includes(searchBy) || result.get("email")?.includes(searchBy)) {
                            return true
                        }
                        if (result.get("user")) {
                            const onlyCanAccessFullNameOfExternalUsers = result.get("user").get("fullName")
                            if (onlyCanAccessFullNameOfExternalUsers?.includes(searchBy)) {
                                return true
                            }
                        }
                        return false
                    }
                    return true
                })

                if (!features?.unlimited_users) {
                    total += 1
                    filteredResults.push(null)
                }

                resolve({
                    items: filteredResults,
                    total: total
                })
            })
            .catch(error => {
                console.error(error)
                reject(error)
            })
        })
    }
    
    const onChange = () => {
        if (filter.current) {
            filter.current.onChange()
        }
    }

    useEffect(() => {
        document.addEventListener("ClinicUser__getList", onChange)
        return () => {
            document.removeEventListener("ClinicUser__getList", onChange)
        }
    }, [])

    return (
        <div id="clinic-setting-user-management">
            <div className="row">
                <div className="col-12">
                    <div className="page-content">
                        <VSList
                            ref={filter}
                            button={permissions?.check(ACL.ADMIN) && {
                                text: "Convidar Usuário(a)",
                                onClick: () => history.push(`/configuracao-clinica/gerenciar-acessos/novo`)
                            }}
                            search={{
                                text: "Buscar por nome ou email do usuário",
                                value: ""
                            }}
                            VSFilterProps={{
                                title: "Filtro de Usuários",
                                onClear: () => {
                                    setProfiles([])
                                    setStatus("0")
                                },
                                onFilter: () => {
                                    onChange()
                                },
                                filters: [
                                    {
                                        title: "Perfil",
                                        defaultValue: [],
                                        value: profiles,
                                        onChange: (values) => {
                                            setProfiles(values)
                                        },
                                        onSearch: (term) => {
                                            return new Promise((resolve, reject) => {
                                                searchProfiles(term, clinic.object)
                                                .then(({ results, count }) => {
                                                    if (results) {
                                                        const options = results.map(profile => ({
                                                            value: profile,
                                                            label: profile.get("name")
                                                        }))
                                                        return resolve({
                                                            count: count,
                                                            options: options
                                                        })
                                                    }
                                                    return resolve([])
                                                })
                                                .catch(error => {
                                                    console.error(error)
                                                    return resolve([])
                                                })
                                            })
                                        },
                                        options: [],
                                        multiple: {
                                            title: "Perfis",
                                            button: "Selecione um perfil",
                                            placeholder: "Procure pelo perfil"
                                        }
                                    },
                                    {
                                        title: "Estado do Convite",
                                        defaultValue: "0",
                                        value: status,
                                        onChange: (value) => {
                                            setStatus(value)
                                        },
                                        options: [
                                            { value: "0", label: "Todos"},
                                            { value: "1", label: "Aceito"},
                                            { value: "2", label: "Recusado"},
                                            { value: "3", label: "Pendente"}
                                        ]
                                    }
                                ]
                            }}
                            pagination={{
                                rowsPerPage: rowsPerPage
                            }}
                            onChange={({ _searchBy, _start, _end, _orderBy, _page }) => {
                                return getClinicUsers(_searchBy, _start, _end, _orderBy, _page)
                            }}
                            renderItemStyle="col"
                            renderItem={(item) => item ? (
                                <VetView
                                    object={item}
                                    history={history}
                                />
                            ) : 
                            (permissions?.check(ACL.ADMIN, ACL.Rule.VIEW) || clinic?.isOwner) && (
                                <div className="col-md-12 col-lg-6 col-xl-4">
                                    <div className="vsbox h-100">
                                        <div className="vsbox-head p-0" style={{ backgroundColor: "var(--vsprontuario-primary-color)" }}>
                                            <svg width="100%" viewBox="0 0 312 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_140_7726)">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M321.688 78.8337C322.371 78.934 323.534 80.509 323.265 82.3417C323.645 84.5037 323.376 86.3363 322.29 88.985L306.577 108.909C305.692 110.184 304.125 111.358 302.237 111.548C300.088 111.935 298.267 111.667 297.002 110.78L283.995 102.784L283.211 103.372C280.961 104.445 278.939 105.552 276.04 106.297L271.054 121.244C269.386 123.105 266.513 125.257 263.099 124.756L238.066 121.079C233.514 120.411 231.937 116.903 231.456 115.428L230.975 113.953L231.08 98.9913C230.692 98.4663 230.043 98.1369 229.428 97.5784C227.969 96.4282 226.317 95.0154 225.153 93.4403L210.396 97.3577C208.93 97.8443 207.109 97.5769 205.162 96.5889C203.215 95.6008 202.279 94.0592 201.764 92.8135L192.444 69.2125C191.963 67.7377 192.232 65.9051 192.434 64.5306C192.737 62.4689 194.372 60.8368 195.838 60.3501L206.801 53.7695L207.474 49.1879C207.575 48.5006 207.904 47.8468 208.005 47.1595C208.333 46.5057 208.434 45.8185 208.535 45.1313L208.602 44.6731L198.797 33.8721C195.5 29.4095 196.687 26.0736 197.412 24.3078L213.807 4.48369C217.11 0.990404 220.297 1.45834 221.662 1.65889L223.027 1.85942L236.035 9.85448C239.069 8.19385 241.74 7.41608 244.572 7.12993L248.192 -8.01797C249.86 -9.87915 252.734 -12.0314 256.147 -11.53L281.863 -7.75307C285.732 -7.18485 288.742 -3.93444 288.17 -0.0400666L288.267 13.5474C290.113 15.2227 292.415 16.9649 295.66 18.6116L310.417 14.6944C311.884 14.2077 313.704 14.475 315.651 15.4631C317.599 16.4512 318.535 17.9928 319.049 19.2385L328.37 42.8395C329.331 45.789 328.658 50.3706 324.976 51.7019L312.546 58.7692L311.873 63.3508C311.772 64.038 311.443 64.6919 311.343 65.3792C311.014 66.033 310.913 66.7201 310.812 67.4074L310.711 68.0946L321.688 78.8337ZM255.788 82.7281C270.352 84.8673 283.936 74.6932 286.089 60.032C288.243 45.3708 278.158 31.7205 263.593 29.5814C249.028 27.4422 235.445 37.6164 233.291 52.2776C231.104 67.1679 241.223 80.589 255.788 82.7281Z" fill="#01B5A6"/>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M102.824 73.2628C103.334 73.3376 104.203 74.5139 104.002 75.8825C104.286 77.4971 104.085 78.8656 103.274 80.8436L91.5393 95.7229C90.8787 96.6745 89.7083 97.5512 88.2984 97.6937C86.6935 97.9822 85.3338 97.7825 84.3894 97.1196L74.6757 91.1489L74.0903 91.5874C72.41 92.3892 70.8998 93.2159 68.7348 93.7717L65.0118 104.934C63.7659 106.324 61.6201 107.931 59.0709 107.557L40.3761 104.811C36.9771 104.312 35.7991 101.692 35.44 100.591L35.081 99.4897L35.1595 88.3162C34.8698 87.9242 34.385 87.6782 33.9254 87.2612C32.8363 86.4021 31.6023 85.3471 30.7334 84.1709L19.713 87.0963C18.6179 87.4597 17.2582 87.26 15.804 86.5221C14.3498 85.7843 13.6509 84.633 13.2667 83.7027L6.30628 66.0778C5.94717 64.9764 6.1481 63.6078 6.29885 62.5814C6.52498 61.0417 7.74583 59.8229 8.84092 59.4594L17.0281 54.5451L17.5307 51.1236C17.606 50.6104 17.8515 50.1221 17.9269 49.6089C18.1722 49.1206 18.2476 48.6074 18.323 48.0942L18.3732 47.752L11.0505 39.6859C8.58848 36.3533 9.47531 33.8621 10.0162 32.5434L22.2603 17.739C24.7268 15.1303 27.1063 15.4797 28.126 15.6295L29.1457 15.7792L38.8595 21.7499C41.125 20.5097 43.1201 19.9289 45.2349 19.7152L47.938 8.40291C49.1839 7.013 51.3299 5.40576 53.8791 5.78017L73.0836 8.60073C75.9728 9.02507 78.2207 11.4524 77.7936 14.3607L77.8657 24.5077C79.2445 25.7588 80.9632 27.0598 83.3869 28.2896L94.4074 25.3642C95.5025 25.0008 96.862 25.2004 98.3162 25.9383C99.7704 26.6762 100.469 27.8275 100.854 28.7578L107.814 46.3827C108.532 48.5854 108.03 52.0069 105.28 53.0011L95.997 58.2788L95.4945 61.7003C95.4191 62.2135 95.1738 62.7018 95.0985 63.2151C94.8531 63.7033 94.7778 64.2165 94.7024 64.7297L94.6271 65.2429L102.824 73.2628ZM53.6106 76.1711C64.4875 77.7686 74.6315 70.1707 76.2396 59.2219C77.8476 48.2731 70.3167 38.0792 59.4397 36.4817C48.5628 34.8842 38.4188 42.4822 36.8107 53.431C35.1776 64.5509 42.7336 74.5736 53.6106 76.1711Z" fill="#01B5A6"/>
                                                    <path d="M170.878 -4.01552C164.793 -7.21204 155.538 -7.64031 154.035 -7.84277C136.04 -10.2606 125.208 15.8878 124.57 23.8109C123.394 38.4152 115.669 40.6423 104.795 49.788C82.9876 68.1301 89.3845 89.4389 101.306 97.3581C108.459 102.108 121.069 97.3581 121.069 97.3581C121.249 97.4827 155.558 78.179 172.467 68.8659C197.821 65.2645 186.382 4.12178 170.878 -4.01552Z" fill="#233862"/>
                                                    <path d="M158.292 60.9414C158.366 60.9881 149.972 63.8537 144.358 61.3696C139.078 59.0375 135.076 50.3473 135.153 50.3434C137.556 50.2305 140.192 48.1319 142.224 34.3491L143.248 34.7151L160.449 40.9057C160.449 40.9057 159.203 46.4227 158.397 51.6789C157.716 56.0901 157.35 60.3145 158.292 60.9414Z" fill="#C36BE0"/>
                                                    <path d="M160.447 40.9064C160.447 40.9064 159.201 46.4234 158.395 51.6796C148.518 49.9002 144.64 40.0576 143.246 34.7158L160.447 40.9064Z" fill="#9141AF"/>
                                                    <path d="M163.01 46.3827C163.01 46.3827 139.478 43.2251 141.67 28.5975C143.862 13.9737 142.3 3.71443 157.419 5.11218C172.537 6.50993 174.612 12.5292 175.067 17.5868C175.523 22.6522 169.188 46.8305 163.01 46.3827Z" fill="#C36BE0"/>
                                                    <path d="M174.208 9.57632C174.208 9.57632 159.144 22.5571 148.897 23.6044C138.649 24.6517 134.771 21.3618 134.771 21.3618C134.771 21.3618 140.81 16.347 144.447 8.25255C145.517 5.86587 147.779 4.23062 150.384 4.0048C157.704 3.37406 171.572 3.08595 174.208 9.57632Z" fill="#233862"/>
                                                    <path d="M164.795 8.41161C164.795 8.41161 169.833 13.2862 171.422 16.8993C172.952 20.3878 173.411 28.7276 172.192 32.0409C172.192 32.0409 180.046 16.7747 175.599 9.35383C170.884 1.48907 164.795 8.41161 164.795 8.41161Z" fill="#233862"/>
                                                    <path d="M178.106 95.6054C177.394 143.242 177.912 133.286 172.702 137.806C171.444 138.897 167.917 139.543 163.272 139.85C148.664 140.82 122.991 138.414 122.013 135.716C118.645 126.375 121.024 123.475 118.213 109.174C117.921 107.652 117.559 106.009 117.131 104.214C114.787 94.3906 112.506 91.05 120.822 75.2738C128.364 60.9809 136.197 49.3824 136.902 49.799C156.797 61.6078 158.401 51.6834 158.401 51.6834C158.401 51.6834 178.457 72.3693 178.106 95.6054Z" fill="#D6E5F8"/>
                                                    <path d="M158.396 51.6816C158.396 51.6816 178.868 55.1507 181.27 63.9655C183.673 72.7802 197.822 124.594 197.822 124.594C197.822 124.594 187.414 126.463 182.606 121.655C177.798 116.846 165.88 77.0513 165.88 77.0513L158.396 51.6816Z" fill="#D6E5F8"/>
                                                    <path d="M136.884 49.7881C136.884 49.7881 117.534 50.9795 110.837 60.3237C101.407 73.4758 82.7031 129.522 93.4218 136.997C105.495 145.415 148.366 139.201 148.366 139.201C148.366 139.201 149.791 132.532 147.809 129.308C145.824 126.084 113.566 120.871 112.079 119.383C110.592 117.896 130.69 90.35 130.69 85.8842C130.69 81.4145 136.884 49.7881 136.884 49.7881Z" fill="#D6E5F8"/>
                                                    <path d="M167.975 119.942H237.418L258.306 68H188.972L167.975 119.942Z" fill="#34347F"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_140_7726">
                                                        <rect width="312" height="80" rx="8" fill="white"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                        <div className="vsbox-body">
                                            <div className="vsbox-title">Você está usando o Prontuário Gratuito</div>
                                            <p>{ getTrialEndsAtForProduct(features) ? "Assine" : "Experimente"} o Prontuário PRO para convidar quantos usuários quiser para a sua clínica e passe a controlar o que cada usuário acessa.</p>
                                            <button
                                                className="vsbox-btn vsbox-btn-primary w-100"
                                                onClick={() => {
                                                    saveTrackersProSubscription(features)
                                                    redirectToCheckout(history)
                                                }}
                                            >
                                                { getTrialEndsAtForProduct(features) ? (
                                                    "Assine o Prontuário PRO"
                                                ) : (
                                                    "Experimentar 15 dias grátis"
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            renderEmpty={() => (
                                <VSEmpty
                                    icon={<IconPrimaryPaw width="32px" height="32px" />}
                                    title="Você ainda não possui nenhum animal cadastrado no Prontuário"
                                    text="Cadastre o primeiro animal e comece a aproveitar todos os benefícios de gestão de atendimentos do Prontuário Vet Smart"
                                />
                            )}
                            renderNotFound={() => (
                                <VSEmpty
                                    icon={<IconPrimaryPaw width="32px" height="32px" />}
                                    title="Não encontramos nenhum resultado para a sua busca"
                                    text="Tente refazer sua busca utilizando mais caracteres ou crie um novo cadastro utilizando o botão abaixo"
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        features: state.features,
        clinic: state.clinic,
        permissions: state.permissions
    }
}

export default connect(mapStateToProps)(ClinicSettingUserManagement)
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Calendar, dayjsLocalizer, Views } from 'react-big-calendar'
import { connect } from 'react-redux';
import Reactour from 'reactour'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'

const localizer = dayjsLocalizer(dayjs)

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { Button, CircularProgress, FormControl, MenuList, MenuItem, Popover, Select, Popper, Grow, Paper, ClickAwayListener, Chip } from '@mui/material';

import { CALENDAR_BLANK, CALENDAR_BLOCK, CALENDAR_CANCELED, CALENDAR_CANCELED_MESSAGE, CALENDAR_CONFIRMED, CALENDAR_DONE, CALENDAR_NOSHOW, deleteTag, getProfessionals, isTimeAvailable, searchTags, searchUsers, statusOptions } from './Calendar'
import { searchPatients } from '../patient/Patient';
import { searchOwners } from '../owner/Owner';
import { searchServices } from '../service/Service';
import { dayjs, getWeekDay, setAmericaFortalezaTimezone, useQuery, useWindowSize } from '../../utils';
import { ACL } from '../../constants';

import { ReactComponent as IconAdd } from "../../assets/icon-add-o.svg"
import { ReactComponent as IconArrow } from "../../assets/icon-arrow-left.svg" 
import { ReactComponent as IconCalendar } from "../../assets/icon-calendar.svg"
import { ReactComponent as IconCalendarX } from "../../assets/icon-calendar-x.svg"
import { ReactComponent as IconCalendarCheck } from "../../assets/icon-calendar-check.svg"
import { ReactComponent as IconCalendarDay } from "../../assets/icon-calendar-day.svg"
import { ReactComponent as IconCalendarMonth } from "../../assets/icon-calendar-month.svg"
import { ReactComponent as IconCalendarView } from "../../assets/icon-calendar-view.svg"
import { ReactComponent as IconCalendarWeek } from "../../assets/icon-calendar-week.svg"
import { ReactComponent as IconCalendarList } from "../../assets/icon-calendar-list.svg"
import { ReactComponent as IconClose } from "../../assets/icon-close.svg"
import { ReactComponent as IconIdentity } from "../../assets/icon-identity.svg"
import { ReactComponent as IconQuestion } from "../../assets/icon-question-t.svg"
import { ReactComponent as IconWarningO } from "../../assets/icon-warning-o.svg"

import VSError from '../../components/vsError/VSError';
import VSModal from '../../components/vsModal/VSModal';
import CalendarElementView from './CalendarView';
import VSFilter from '../../components/vsList/VSFilter';
import CardFeedback from '../../components/cardFeedback/CardFeedback';
import VSFilterSelect from '../../components/vsList/VSFilterSelect';
import TagForm from './TagForm';
import CalendarSurvey from './CalendarSurvey';
import CalendarAgendaView from './CalendarAgendaView';

const viewNameLocalStorage = "vscalendar-view"

import "./CalendarList.scss"
const CalendarList = (props) => {

    const {
        clinic,
        history,
        permissions
    } = props

    const {
        width,
        height
    } = useWindowSize()

    const showNewAgendaAlert = dayjs().isBefore(dayjs("2024-05-25")) && history.pathname == "/agenda"

    const LOCALSTORAGE_AGENDA = "vscalendar-agenda-view-tour"
    
    const isMobile = width < 768
    
    const DnDCalendar = withDragAndDrop(Calendar)
    
    const isMounted = useRef(null)
    const filterRef = useRef(null)
    const anchorRef = useRef(null)
    const selectViewRef = useRef(null)
    const calendarRef = useRef(null)
    const tipRef = useRef(null)

    const [ open, setOpen ] = useState(false)
    const [ anchor, setAnchor ] = useState({})

    const [ selectViewOpen, setSelectViewOpen ] = useState(false)
    const [ showAgendaTour, setShowAgendaTour ] = useState(false)
    const [ showTip, setShowTip ] = useState(false)

    const [ view, setView ] = useState(localStorage.getItem(viewNameLocalStorage) || Views.MONTH)
    const [ object, setObject ] = useState(null)

    const [ loading, setLoading ] = useState(false)
    const [ loadingEvents, setLoadingEvents ] = useState(false)

    const [ miniCalendar, setMiniCalendar ] = useState(null)

    const [ patients, setPatients ] = useState([])
    const [ owners, setOwners ] = useState([])
    const [ users, setUsers ] = useState([])
    const [ services, setServices ] = useState([])
    const [ tags, setTags ] = useState([])
    const [ status, setStatus ] = useState("all")

    const [ date, setDate ] = useState(new Date())
    const [ professionals, setProfessionals ] = useState([])
    const [ events, setEvents ] = useState([])
    const [ backgroundEvents, setBackgroundEvents ] = useState([])

    const [ isSurveyVisible, setIsSurveyVisible ] = useState(false)

    const [ error, setError ] = useState({})

    const [ calendarHeight, setCalendarHeight ] = useState(0)
    const [ scrollToTime, setScrollToTime ] = useState(dayjs().hour(6).minute(0).second(0).toDate())

    const workHours = clinic.object?.get("workHours") || []

    const startDate = dayjs(date).startOf("month").startOf("week").startOf("day")
    const endDate = dayjs(date).endOf("month").endOf("week").endOf("day")

    const params = useQuery()

    const options = [
        {
            value: 1,
            label: "Agendamento para um animal",
            icon: <IconIdentity width={24} height={24} fill="#FFFFFF" />
        },
        {
            value: 2,
            label: "Evento em branco",
            icon: <IconCalendar width={24} height={24} fill="#FFFFFF" />
        },
        {
            value: 3,
            label: "Bloqueio de horário",
            icon: <IconCalendarX width={24} height={24} fill="#FFFFFF" />
        }
    ]

    const {
        views 
    } = useMemo(() => ({
            views: {
                month: true,
                week: true,
                day: true,
                agenda: CalendarAgendaView
            },
    }), [])

    const components = useMemo(() => ({
        timeGutterHeader: () => <p>dia<br/>inteiro</p>,
        eventWrapper: (props) => {
            if (view == Views.MONTH) {
                return React.Children.map(props.children, child1 => {
                    return React.Children.map(child1.props.children, child2 => {
                        return React.Children.map(child2.props.children, child3 => (
                            <div {...child2.props}>
                                <div {...child3.props}>
                                    <span>{ dayjs(props.event.start).format("HH:mm") }</span>
                                    { child3.props.children }
                                </div>
                            </div>
                        ))
                    })
                });
            }
            return props.children
        }
    }))

    const formats = useMemo(() => ({
        dayFormat: 'DD',
        dayHeaderFormat: 'LL',
        dayRangeHeaderFormat: ({ start, end }, culture, localizer) => `${localizer.format(start, 'DD', culture)} de ${localizer.format(end, 'LL', culture)}`,
        dateFormat: 'DD',
        eventTimeRangeFormat: ({ start, end }, culture, localizer) => `${localizer.format(start, 'HH:mm', culture)} - ${localizer.format(end, 'HH:mm', culture)}`,
        monthHeaderFormat: (date, culture, localizer) => `${localizer.format(date, 'MMMM', culture)} de ${localizer.format(date, 'YYYY', culture)}`,
        weekdayFormat: isMobile ? 'ddd' : 'dddd',
        // agendaDateFormat: (date, culture, localizer) => null,
        // agendaHeaderFormat: ({ start, end }, culture, localizer) => null,
        // agendaTimeFormat: (date, culture, localizer) => null,
        // agendaTimeRangeFormat: ({ start, end }, culture, localizer) => null,
        // eventTimeRangeEndFormat: (date, culture, localizer) => null,
        // eventTimeRangeStartFormat: (date, culture, localizer) => null,
        // selectRangeFormat: ({ start, end }, culture, localizer) => null,
        // timeGutterFormat: 'HH:mm',
    }), [ isMobile ])

    const onResizeOrDropEvent = ({ event, start, end, resourceId }) => {
        if (!permissions?.check(ACL.CALENDAR, ACL.Rule.EDIT, event.object)) {
            Swal.fire(
                'Desculpe',
                `Você não possui permissões para alterar eventos, procure o administrador da clínica`,
                'error'
            )
            return
        }
        Swal.fire({
            showConfirmButton: false,
            customClass: {
                popup: 'p-0',
                content: 'p-0',
            },
            html: (
                <VSModal
                    title="Tem certeza que deseja editar este evento?"
                    text="Você esta editando o horário desse evento. Você tem certeza que deseja continuar?"
                    options={[
                        {
                            type: "cancel",
                            text: "Cancelar",
                            action: () => {
                                Swal.close()
                            }
                        },
                        {
                            type: "confirm",
                            text: "Editar Evento",
                            action: () => {
                                const object = event.object
                                const userId = resourceId && resourceId != event.resourceId ? {
                                    userId: resourceId
                                } : {}
                                Parse.Cloud.run("updateCalendarForClinic", {
                                    data: {
                                        ...userId,
                                        startDate: dayjs(start).toDate(),
                                        endDate: dayjs(end).toDate()
                                    },
                                    objectId: object.id,
                                    clinicId: clinic.objectId,
                                    patientId: object.get("patient").id
                                })
                                .then(_calendar => {
                                    getEventsOrError()
                                    Swal.close()
                                })
                                .catch(error => {
                                    console.error(error)
                                    Swal.fire(
                                        'Desculpe',
                                        `Ocorreu algum erro ao tentar editar o evento`,
                                        'error'
                                    )
                                })
                            }
                        }
                    ]}
                />
            )
        })
    }

    const getEventsOrError = async () => {
        setLoadingEvents(true)

		const query = new Parse.Query("MRCalendar")
		query.equalTo("clinic", clinic.object)
		query.equalTo("isDeleted", false)
		query.greaterThanOrEqualTo("startDate", startDate.toDate())
		query.lessThanOrEqualTo("endDate", endDate.toDate())
        if (patients?.length > 0) {
            query.containedIn("patient", patients.map(({ value }) => value))
        }
        if (owners?.length > 0) {
            const queryPatientsByOwner = new Parse.Query("Patient")
            queryPatientsByOwner.equalTo("clinic", clinic.object)
            queryPatientsByOwner.equalTo("isDeleted", false)
            queryPatientsByOwner.containedIn("owner", owners.map(({ value }) => value))
            query.matchesQuery("patient", queryPatientsByOwner)
        }
        if (users?.length > 0) {
            query.containedIn("user", users.map(({ value }) => value))
        }
        if (services?.length > 0) {
            query.containedIn("services.objectId", services.map(({ value }) => value.id))
        }
        if (tags?.length > 0) {
            query.containedIn("tags", tags.map(({ value }) => value))
        }
        if (status != "all") {
            query.equalTo("status", status)
        }
		query.include("tags")
		query.include("user")
		query.include("sale")
		query.include("patient")
		query.include("patient.owner")
		query.include("messages")
		query.limit(100000)
        try {
            const results = await query.find()

            const formatEvent = event => {
                const isFullTime = dayjs(event.get("startDate")).format("HHmm") == "0000" && dayjs(event.get("endDate")).format("HHmm") == "2359"
                return {
                    id: event.id,
                    title: event.get("title") || "Sem título",
                    start: setAmericaFortalezaTimezone(event.get("startDate")),
                    end: setAmericaFortalezaTimezone(event.get("endDate")),
                    allDay: isFullTime,
                    resourceId: event.get("user") ? event.get("user").id : professionals.map(user => user.id),
                    object: event
                }
            }

            const _events = results.map(formatEvent)
            setEvents(_events)
            // const _backgroundEvents = results.filter(event => event.get("status") == CALENDAR_BLOCK).map(formatEvent)
            // setBackgroundEvents(_backgroundEvents)
            setLoadingEvents(false)
        } catch(error) {
            const erroMessage = "Não foi possível buscar os eventos"
            console.error(error)
            Swal.fire(
                'Desculpe',
                erroMessage,
                'error'
            )
            .then(_ => {
                setLoadingEvents(false)
                setError({
                    message: erroMessage,
                    function: "getEventsOrError"
                })
            })
        }
    }

    const initializeOrError = () => {
        setLoading(true)

        getProfessionals(clinic.object)
        .then(_professionals => {
            if (params.get("date")) {
                setView(Views.DAY)
                setDate(dayjs(params.get("date"), "YYYY-MM-DD").toDate())
            }
            var filteredProfessionals = _professionals.filter(user => !params.get("user") || params.get("user") == user.id)
            if (filteredProfessionals.length == 0) {
                filteredProfessionals = _professionals
            }
            setProfessionals(
                filteredProfessionals
                .map(professional => ({
                    id: professional.id,
                    name: professional.get("fullName"),
                    workHours: professional.clinicUser?.get("workHours") || {}
                }))
            )
            setLoading(false)
        })
        .catch(error => {
            console.error(error)
            Swal.fire(
                'Desculpe',
                error,
                'error'
            )
            .then(_ => {
                setLoading(false)
                setError({
                    message: error,
                    function: "initializeOrError"
                })
            })
        })
    }

    const getResources = () => {
        if (users?.length) {
            return professionals?.filter(pro => users.find(user => user.value?.id == pro.id))
        }
        return professionals
    }

    const refresh = () => {
        getEventsOrError()
    }

    const calculateCalendarHeight = () => {
        const headerHeight = document.getElementById("header")?.offsetHeight || 0
        const filterHeight = document.getElementsByClassName("page-filter")?.[0]?.offsetHeight || 0
        const agendaHeight = document.getElementsByClassName(view == Views.MONTH ? "rbc-month-header" : "rbc-time-header")?.[0]?.offsetHeight || 0
        const surveyHeight = document.getElementById("calendar-survey")?.offsetHeight + 16 || 8
        const adjustment   = width >= 768 ? 0 : -16
        setCalendarHeight(height - headerHeight - filterHeight - agendaHeight - surveyHeight + adjustment)
    }

    const removeAgendaViewTour = () => {
        localStorage.setItem(LOCALSTORAGE_AGENDA, "true")
        setShowAgendaTour(false)
    }

    useEffect(() => {
        if (!loading && showNewAgendaAlert) {
            const showReactourAgenda = !Boolean(localStorage.getItem(LOCALSTORAGE_AGENDA))
            setShowAgendaTour(showReactourAgenda)
        }
    }, [ loading ])

    useEffect(() => {
        calculateCalendarHeight()
    }, [ view, width, height, isSurveyVisible ])

    useEffect(() => {
        let _scrollToTime = dayjs("23:59", "HH:mm")
        if (view == Views.DAY) {
            const weekDay = getWeekDay(date)
            professionals.map(professional => {
                if (professional.workHours && professional.workHours[weekDay]) {
                    const time = professional.workHours[weekDay][0]
                    const startTime = dayjs(time.startTime, "HH:mm")
                    if (startTime.isBefore(_scrollToTime)) {
                        _scrollToTime = dayjs(time.startTime, "HH:mm")
                    }
                }
            })
        } else if (view == Views.WEEK && workHours) {
            Object.keys(workHours).map(weekDay => {
                if (workHours[weekDay][0]) {
                    const time = workHours[weekDay][0]
                    const startTime = dayjs(time.startTime, "HH:mm")
                    if (startTime.isBefore(_scrollToTime)) {
                        _scrollToTime = dayjs(time.startTime, "HH:mm")
                    }
                }
            })
        }
        if (_scrollToTime.format("HH:mm") !== "23:59") {
            if (parseInt(_scrollToTime.format("HHmm")) > 30) {
                _scrollToTime = dayjs(_scrollToTime).subtract(30, "minutes")
            }
            setScrollToTime(dayjs(date).hour(_scrollToTime.format("HH")).minute( _scrollToTime.format("mm")).second(0).toDate())
        } else {
            setScrollToTime(dayjs(date).hour(6).minute(0).second(0).toDate())
        }
    }, [ professionals, view, date ])

    useEffect(() => {
        if (professionals) {
            getEventsOrError()
        }
    }, [ professionals, date, view ])

    useEffect(() => {
        if (!permissions?.check(ACL.CALENDAR, ACL.Rule.VIEW)) {
            permissions?.error()
            return
        }
    }, [ permissions ])

    useEffect(() => {
        if (clinic && clinic.isLoaded && clinic.object) {
            initializeOrError()
        }
    }, [ clinic ])

    useEffect(() => {
        document.addEventListener("Calendar__getList", refresh)
        isMounted.current = true
        return () => {
            document.removeEventListener("Calendar__getList", refresh)
            isMounted.current = false
        }
    }, [])

    const isPeriodWithinSameMonth = dayjs(date).startOf("week").format("M") == dayjs(date).endOf("week").format("M")
    const isMontlyView = view == Views.MONTH || view == Views.AGENDA

    return loading ? (
        <div className="row data-loading">
            <div className="col">
                <CircularProgress />
            </div>
        </div>
    ) : error?.message ? (
        <VSError
            message={error.message}
            onClose={() => {
                switch(error.function) {
                    case "getEventsOrError":
                        getEventsOrError()
                        break
                    case "initializeOrError":
                        initializeOrError()
                        break
                    default:
                }
            }}
        />
    ) : clinic && clinic.object && (
        <div id="calendar-view">
            <CalendarSurvey onChange={(isVisible) => setIsSurveyVisible(isVisible)} />
            <div className="page-content">
                <div className="row g-3 mb-0 mb-md-3 align-items-center page-filter">
                    <div className="col-12 d-none d-md-block">
                        <div className="page-title">
                            Agenda
                        </div>
                    </div>
                    <div className="col-auto d-none d-md-block">
                        <Button
                            variant="contained"
                            className="btn-today"
                            disableElevation
                            onClick={() => {
                                setDate(new Date())
                            }}
                        >
                            Hoje
                        </Button>
                    </div>
                    <div className="col-auto d-none d-md-block">
                        <FormControl size="small" className="btn-calendar">
                            <Select
                                id="agenda-view"
                                open={selectViewOpen}
                                onOpen={() => setSelectViewOpen(true)}
                                onClose={() => setSelectViewOpen(false)}
                                value={view}
                                onChange={({ target: { value }}) => {
                                    setView(value)
                                    localStorage.setItem(viewNameLocalStorage, value)
                                }}
                                displayEmpty
                            >
                                <MenuItem value={Views.MONTH}>Mês</MenuItem>
                                <MenuItem value={Views.WEEK}>Semana</MenuItem>
                                <MenuItem value={Views.DAY}>Dia</MenuItem>
                                <MenuItem value={Views.AGENDA}>Lista de eventos { view !== Views.AGENDA && showNewAgendaAlert && <Chip label="NOVO" size="small" color="secondary" className="ms-2" /> }</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col d-none d-md-block">
                        <Button
                            ref={isMobile ? null : tipRef}
                            variant="contained"
                            className="btn-tip"
                            disableElevation
                            onClick={() => setShowTip(true)}
                        >
                            <IconQuestion width={24} height={24} fill="var(--vsprontuario-primary-color)" />
                        </Button>
                    </div>
                    { permissions?.check(ACL.CALENDAR, ACL.Rule.EDIT) && (
                        <div className="col-auto mt-0 mt-md-3 text-end area-btn-mobile">
                            <button
                                ref={anchorRef}
                                className={`btn-main-mobile btn-submit btn-square ${ open ? "btn-opened" : "" }`}
                                onClick={() => {
                                    setOpen(!open)
                                    setAnchor({
                                        contentElement: anchorRef.current,
                                        getBoundingClientRect: () => anchorRef.current.getBoundingClientRect(),
                                        params: {
                                            date: date
                                        }
                                    })
                                }}
                            >
                                <div className="d-none d-md-block" style={{ width: "268px" }}>
                                    <div className="row text-start">
                                        <div className="col">
                                            CRIAR NOVO EVENTO
                                        </div>
                                        <div className="col-auto">
                                            <IconArrow width={12} height={12} fill="#FFFFFF" className="ms-2" style={{ transform: `rotate(${ open ? "90" : "270" }deg)` }} />
                                        </div>
                                    </div>
                                </div>
                                { open ? (
                                    <span className="d-block d-md-none">
                                        Novo evento
                                        <IconClose width={20} height={20} fill="var(--vsprontuario-primary-color)" className="ms-2" />
                                    </span>
                                ) : (
                                    <span className="d-block d-md-none">
                                        <IconAdd width={24} height={24} fill="#FFFFFF" />
                                    </span>
                                )}
                            </button>
                            <Popper
                                sx={{
                                    zIndex: 9999,
                                    width: "300px"
                                }}
                                open={open}
                                anchorEl={anchor}
                                placement='bottom-end'
                                transition
                                disablePortal
                            >
                                {({ TransitionProps }) => (
                                    <Grow
                                        {...TransitionProps}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={() => {
                                                if (anchorRef.current && anchorRef.current.contains(event.target)) {
                                                    return;
                                                }

                                                setOpen(false);
                                            }}>
                                                <MenuList id="split-button-menu" autoFocusItem>
                                                    {options.map(option => (
                                                        <MenuItem
                                                            key={option.value}
                                                            onClick={(event) => {
                                                                setOpen(false)
                                                                switch (option.value) {
                                                                    case 1:
                                                                        history.push(
                                                                            "/animal/selecionar/agenda", 
                                                                            anchor.params
                                                                        )
                                                                        break
                                                                    case 2:
                                                                        history.push(
                                                                            "/agenda/novo/branco", 
                                                                            anchor.params
                                                                        )
                                                                        break
                                                                    case 3:
                                                                        history.push(
                                                                            "/agenda/novo/bloqueio", 
                                                                            anchor.params
                                                                        )
                                                                        break
                                                                }
                                                            }}
                                                        >
                                                            { option.label }
                                                            <span className="calendar-option">
                                                                { option.icon }
                                                            </span>
                                                        </MenuItem>
                                                    ))}
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </div>
                    )}
                    <div className="col-12 filter-mobile">
                        <div className="filter">
                            <div className="row g-3 align-items-center justify-content-between">
                                <div className="col-auto d-block d-md-none order-2">
                                    <IconQuestion
                                        ref={isMobile ? tipRef : null}
                                        width={24}
                                        height={24}
                                        fill="var(--vsprontuario-primary-color)"
                                        onClick={() => setShowTip(true)}
                                    />
                                </div>
                                <div className="col-auto d-block d-md-none order-2 margin-mobile">
                                    <VSFilterSelect
                                        ref={selectViewRef}
                                        text={<IconCalendarView width={24} height={24} />}
                                        title="Visualização da agenda"
                                        options={[
                                            {
                                                label: (
                                                    <div className="row g-3 align-items-center">
                                                        <div className="col-auto">
                                                            <IconCalendarDay width={24} height={24} fill="#00756C" />
                                                        </div>
                                                        <div className="col">
                                                            Dia
                                                        </div>
                                                    </div>
                                                ),
                                                value: Views.DAY
                                            },
                                            {
                                                label: (
                                                    <div className="row g-3 align-items-center">
                                                        <div className="col-auto">
                                                            <IconCalendarWeek width={24} height={24} fill="#00756C" />
                                                        </div>
                                                        <div className="col">
                                                            Semana
                                                        </div>
                                                    </div>
                                                ),
                                                value: Views.WEEK
                                            },
                                            {
                                                label: (
                                                    <div className="row g-3 align-items-center">
                                                        <div className="col-auto">
                                                            <IconCalendarMonth width={24} height={24} fill="#00756C" />
                                                        </div>
                                                        <div className="col">
                                                            Mês
                                                        </div>
                                                    </div>
                                                ),
                                                value: Views.MONTH
                                            },
                                            {
                                                label: (
                                                    <div className="row g-3 align-items-center">
                                                        <div className="col-auto">
                                                            <IconCalendarList width={24} height={24} fill="#00756C" />
                                                        </div>
                                                        <div className="col">
                                                            Lista de eventos { showNewAgendaAlert && <Chip label="NOVO" size="small" color="secondary" className="ms-2" /> }
                                                        </div>
                                                    </div>
                                                ),
                                                value: Views.AGENDA
                                            }
                                        ]}
                                        onSelect={(value) => setView(value)}
                                    />
                                </div>
                                <div className="col-auto d-none d-md-block">
                                    <Button
                                        onClick={() => setDate(dayjs(date).subtract(1, isMontlyView ? "month" : (view == Views.WEEK ? "week" : "day")).toDate())}
                                    >
                                        <IconArrow width={12} height={16} fill="var(--vsprontuario-primary-color)" />
                                    </Button>
                                </div>
                                <div className="col-auto d-none d-md-block">
                                    <Button
                                        onClick={() => setDate(dayjs(date).add(1, isMontlyView ? "month" : (view == Views.WEEK ? "week" : "day")).toDate())}
                                    >
                                        <IconArrow width={12} height={16} fill="var(--vsprontuario-primary-color)" style={{ transform: "rotate(180deg)" }} />
                                    </Button>
                                </div>
                                <div className="col order-1 margin-mobile">
                                    <Button
                                        onClick={(e) => {
                                            setMiniCalendar(e.target.closest(".filter"))
                                        }}
                                        className={`btn-mini-calendar ${Boolean(miniCalendar) ? "open" : ""}`}
                                    >
                                        <div className="row gx-3 gy-0 align-items-center">
                                            <div className="col-auto pe-0 order-3 order-md-1 d-none d-md-block">
                                                <IconCalendarCheck width={24} height={24} className="icon-calendar" fill="#999999" />
                                            </div>
                                            <div className="col order-1 order-md-2 calendar-description">
                                                { isMontlyView ? (
                                                    dayjs(date).format(`${ isMobile ? "MMM" : "MMMM"} [de] YYYY`)
                                                ) : view == Views.WEEK ? (
                                                    `${ dayjs(date).startOf("week").format(isPeriodWithinSameMonth ? "DD" : `DD ${isMobile ? "MMM" : "MMMM"}`) } - ${ dayjs(date).endOf("week").format(`DD [de] ${isMobile ? "MMM" : "MMMM"} [de] YYYY`) }`
                                                ) : (
                                                    dayjs(date).format(isMobile ? "ll" : "LL")
                                                )}
                                            </div>
                                            <div className="col-auto px-0 order-2 d-md-none">
                                                <IconArrow width={16} height={12} fill="#808080" className="icon-arrow" />
                                            </div>
                                        </div>
                                    </Button>
                                    <Popover
                                        id="calendar-picker"
                                        open={Boolean(miniCalendar)}
                                        anchorEl={miniCalendar}
                                        onClose={() => setMiniCalendar(null)}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                                            <DateCalendar
                                                views={["day"]}
                                                format="DD/MM/YYYY"
                                                value={dayjs(date)}
                                                onChange={(value) => {
                                                    setDate(value.toDate())
                                                    setMiniCalendar(null)
                                                }}
                                                showDaysOutsideCurrentMonth={true}
                                                dayOfWeekFormatter={(_, date) => dayjs(date).format("ddd").toUpperCase().charAt(0)}
                                            />
                                        </LocalizationProvider>
                                    </Popover>
                                </div>
                                <div className="col-auto d-block d-md-none order-3">
                                    <Button
                                        onClick={(e) => {
                                            setDate(new Date())
                                        }}
                                        className={`btn-mini-calendar ${Boolean(miniCalendar) ? "open" : ""}`}
                                        style={{
                                            minWidth: "40px"
                                        }}
                                    >
                                        <IconCalendarCheck width={24} height={24} className="icon-calendar" fill="var(--vsprontuario-primary-color)" />
                                    </Button>
                                </div>
                                <div className="col-12 col-md-auto text-end margin-mobile order-4">
                                    <VSFilter
                                        ref={filterRef}
                                        title="Filtros de evento"
                                        onClear={() => {
                                            setPatients([])
                                            setOwners([])
                                            setUsers([])
                                            setServices([])
                                            setStatus("all")
                                            setTags([])
                                        }}
                                        onFilter={() => {
                                            getEventsOrError()
                                        }}
                                        filters={[
                                            {
                                                title: "Animais",
                                                defaultValue: [],
                                                value: patients,
                                                onChange: (selectedOptions) => setPatients(selectedOptions),
                                                onSearch: (term) => {
                                                    return new Promise((resolve, reject) => {
                                                        searchPatients(term, clinic.object)
                                                        .then(({ results, count }) => {
                                                            if (results) {
                                                                const options = results.map(patient => ({
                                                                    value: patient,
                                                                    label: patient.get("name")
                                                                }))
                                                                return resolve({
                                                                    count: count,
                                                                    options: options
                                                                })
                                                            }
                                                        })
                                                        .catch(error => {
                                                            console.error(error)
                                                            return resolve([])
                                                        })
                                                    })
                                                },
                                                options: [],
                                                multiple: {
                                                    title: "Animais",
                                                    button: "Selecione um animal",
                                                    placeholder: "Procure pelo nome do animal"
                                                }
                                            },
                                            {
                                                title: "Tutores",
                                                defaultValue: [],
                                                value: owners,
                                                onChange: (selectedOptions) => setOwners(selectedOptions),
                                                onSearch: (term) => {
                                                    return new Promise((resolve, reject) => {
                                                        searchOwners(term, clinic.object)
                                                        .then(({ results, count }) => {
                                                            if (results) {
                                                                const options = results.map(owner => ({
                                                                    value: owner,
                                                                    label: owner.get("name")
                                                                }))
                                                                return resolve({
                                                                    count: count,
                                                                    options: options
                                                                })
                                                            }
                                                        })
                                                        .catch(error => {
                                                            console.error(error)
                                                            return resolve([])
                                                        })
                                                    })
                                                },
                                                options: [],
                                                multiple: {
                                                    title: "Tutores",
                                                    button: "Selecione um tutor(a)",
                                                    placeholder: "Procure pelo nome do(a) tutor(a)"
                                                }
                                            },
                                            {
                                                title: "Profissionais",
                                                defaultValue: [],
                                                value: users,
                                                onChange: (selectedOptions) => setUsers(selectedOptions),
                                                onSearch: (term) => {
                                                    return new Promise((resolve, reject) => {
                                                        searchUsers(term, clinic.object)
                                                        .then(({ results, count }) => {
                                                            if (results) {
                                                                const options = results.map(user => ({
                                                                    value: user,
                                                                    label: user.get("fullName")
                                                                }))
                                                                return resolve({
                                                                    count: count,
                                                                    options: options
                                                                })
                                                            }
                                                        })
                                                        .catch(error => {
                                                            console.error(error)
                                                            return resolve([])
                                                        })
                                                    })
                                                },
                                                options: [],
                                                multiple: {
                                                    title: "Profissionais",
                                                    button: "Selecione um profissional",
                                                    placeholder: "Procure pelo nome do profissional"
                                                }
                                            },
                                            {
                                                title: "Serviços",
                                                defaultValue: [],
                                                value: services,
                                                onChange: (selectedOptions) => setServices(selectedOptions),
                                                onSearch: (term) => {
                                                    return new Promise((resolve, reject) => {
                                                        searchServices(term, clinic.object)
                                                        .then(({ results, count }) => {
                                                            if (results) {
                                                                const options = results.map(service => ({
                                                                    value: service,
                                                                    label: service.get("serviceName")
                                                                }))
                                                                return resolve({
                                                                    count: count,
                                                                    options: options
                                                                })
                                                            }
                                                        })
                                                        .catch(error => {
                                                            console.error(error)
                                                            return resolve([])
                                                        })
                                                    })
                                                },
                                                options: [],
                                                multiple: {
                                                    title: "Serviços",
                                                    button: "Selecione um serviço",
                                                    placeholder: "Procure pelo nome do serviço"
                                                }
                                            },
                                            {
                                                title: "Status",
                                                defaultValue: "all",
                                                value: status,
                                                onChange: (value) => setStatus(value),
                                                options: [
                                                    { value: "all", label: "Todos os eventos"},
                                                    ...statusOptions
                                                ]
                                            },
                                            {
                                                title: "Tags",
                                                defaultValue: [],
                                                value: tags,
                                                onChange: (selectedOptions) => setTags(selectedOptions),
                                                onSearch: (term) => {
                                                    return new Promise((resolve, reject) => {
                                                        searchTags(term, clinic.object)
                                                        .then(({ results, count }) => {
                                                            if (results) {
                                                                const options = results.map(tag => ({
                                                                    value: tag,
                                                                    label: tag.get("name")
                                                                }))
                                                                return resolve({
                                                                    count: count,
                                                                    options: options
                                                                })
                                                            }
                                                            return resolve([])
                                                        })
                                                        .catch(error => {
                                                            console.error(error)
                                                            return resolve([])
                                                        })
                                                    })
                                                },
                                                options: [],
                                                multiple: {
                                                    title: "Tags",
                                                    text: <>Use as tags para identificar os seus eventos. <a href="https://pl-vetsmart.zendesk.com/hc/pt-br/categories/4410355279771-Prontu%C3%A1rio" target="_blank">Saiba mais</a>.</>,
                                                    button: "Selecione uma tag",
                                                    placeholder: "Procure pelo nome da tag",
                                                    canCreate: () => permissions?.check(ACL.CALENDAR, ACL.Rule.EDIT),
                                                    canEdit: (object) => !object.get("status"),
                                                    canDelete: (object) => !object.get("status"),
                                                    onDelete: (object, callback) => deleteTag(object, callback),
                                                    FormComponent: TagForm
                                                }
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row g-3 align-items-center page-calendar">
                    <div className={`col-12 calendar ${ loadingEvents ? "calendar-loading" : "" } ${miniCalendar ? "calendar-margin" : ""}`}>
                        { loadingEvents && (
                            <div className="loading">
                                <CircularProgress />
                            </div>
                        )}
                        <DnDCalendar
                            ref={calendarRef}
                            selectedEvent={object}
                            notFoundData={filterRef.current?.getTotalFiltersApplied() ? {
                                title: "Não encontramos nenhum resultado",
                                text: "Tente refazer sua busca utilizando os filtros a cima"
                            } : {
                                title: `Não há eventos para ${dayjs(date).format("MMMM [de] YYYY")}`,
                                text: "Tenha o controle dos seus eventos. Crie e acompanhe-os na sua Agenda."
                            }}
                            components={components}
                            views={views}
                            date={date}
                            defaultView={view}
                            localizer={localizer}
                            formats={formats}
                            toolbar={false}
                            // style={{
                            //     height: view !== Views.AGENDA ? `${calendarHeight}px` : "100%"
                            // }}
                            scrollToTime={scrollToTime}
                            // min={dayjs('08:00', 'HH:mm').toDate()}
                            // max={dayjs('20:00', 'HH:mm').toDate()}
                            messages={{
                                week: 'Semana',
                                work_week: 'Semana de trabalho',
                                day: 'Dia',
                                month: 'Mês',
                                previous: <IconArrow width={18} height={18} fill="var(--vsprontuario-primary-color)" />,
                                next: <IconArrow width={18} height={18} fill="var(--vsprontuario-primary-color)" style={{ transform: "rotate(180deg)" }} />,
                                today: 'Hoje',
                                agenda: 'Diário',
                                showMore: (total) => `Mais ${total}`,
                            }}
                            onView={(view) => {
                                setView(view)
                            }}
                            onNavigate={(newDate) => {
                                setDate(newDate)
                            }}
                            slotPropGetter={(date, resourceId) => {
                                var isValid = true
                                const weekDay = getWeekDay(date)
                                if (resourceId) {
                                    const professional = professionals.find(professional => professional.id == resourceId)
                                    const professionalHours = professional.workHours || {}
                                    isValid = isTimeAvailable(professionalHours[weekDay], date)
                                } else {
                                    isValid = isTimeAvailable(workHours[weekDay], date)
                                }
                                return !isValid ? {
                                    className: "rcb-time-busy"
                                } : {}
                            }}
                            eventPropGetter={(event, start, end, isSelected) => {
                                const object = event.object
                                const color = object?.get("tags")?.[0]?.get("color") || "#00C4B4"
                                const identifierClassName = {
                                    className: event.object.id
                                }
                                const defaultStyle = {
                                    borderRadius: "4px",
                                    fontSize: "12px",
                                    fontWeight: 600
                                }
                                switch (object.get("status")) {
                                    case CALENDAR_CONFIRMED:
                                        return {
                                            ...identifierClassName,
                                            style: {
                                                ...defaultStyle,
                                                border: "1px solid rgba(0, 0, 0, 0.15)",
                                                background: color,
                                                color: "#FFFFFF"
                                            }
                                        }
                                    case CALENDAR_DONE:
                                        return {
                                            ...identifierClassName,
                                            style: {
                                                ...defaultStyle,
                                                border: "1px solid rgba(0, 0, 0, 0.15)",
                                                background: `${color}80`,
                                                color: "#FFFFFF"
                                            }
                                        }
                                    case CALENDAR_NOSHOW:
                                        return {
                                            ...identifierClassName,
                                            style: {
                                                ...defaultStyle,
                                                border: `1px solid ${color}`,
                                                background: `${color}0f`,
                                                textDecoration: "line-through",
                                                color: color
                                            }
                                        }
                                    case CALENDAR_CANCELED:
                                        return {
                                            ...identifierClassName,
                                            style: {
                                                ...defaultStyle,
                                                border: `1px solid #C40000`,
                                                background: `#C40000`,
                                                color: "#FFFFFF"
                                            }
                                        }
                                    case CALENDAR_CANCELED_MESSAGE:
                                        return {
                                            ...identifierClassName,
                                            style: {
                                                ...defaultStyle,
                                                border: `1px solid #0000000f`,
                                                background: `#C400000f`,
                                                color: "#C40000"
                                            }
                                        }
                                    case CALENDAR_BLOCK:
                                        return {
                                            ...identifierClassName,
                                            style: {
                                                ...defaultStyle,
                                                border: `1px solid #FF8A00`,
                                                background: "#FF8A00",
                                                color: "#FFFFFF"
                                            }
                                        }
                                    case CALENDAR_BLANK:
                                        return {
                                            ...identifierClassName,
                                            style: {
                                                ...defaultStyle,
                                                border: `1px solid #7EA5C9`,
                                                background: "#0778D4",
                                                color: "#FFFFFF"
                                            }
                                        }
                                    // case "open": # default
                                    default: 
                                        return {
                                            ...identifierClassName,
                                            style: {
                                                ...defaultStyle,
                                                border: `1px solid ${color}`,
                                                background: "#FFFFFF",
                                                color: color
                                            }
                                        }
                                }
                            }}
                            onSelectSlot={({ start, end, action, bounds, box, resourceId }) => {
                                if (permissions?.check(ACL.CALENDAR, ACL.Rule.EDIT)) {
                                    const isNotMonthViewBecauseTimeAlwaysZero = view != Views.MONTH // 00:00
                                    setAnchor({
                                        contentElement: document.elementFromPoint(box.x, box.y),
                                        getBoundingClientRect: () => ({
                                            ...box,
                                            width: 0,
                                            height: 0,
                                            top: box.y - window.scrollY,
                                            right: box.x,
                                            bottom: box.y,
                                            left: box.x
                                        }),
                                        params: {
                                            professionalId: resourceId,
                                            date: start,
                                            start: isNotMonthViewBecauseTimeAlwaysZero && dayjs(start).format("HH:mm"),
                                            end: isNotMonthViewBecauseTimeAlwaysZero && dayjs(end).format("HH:mm")
                                        }
                                    })
                                    setOpen(true)
                                }
                            }}
                            onSelectEvent={({ object }, event) => {
                                if (event)
                                    object.event = event
                                setObject(object)
                            }}
                            onEventDrop={onResizeOrDropEvent}
                            onEventResize={onResizeOrDropEvent}
                            dayLayoutAlgorithm={'no-overlap'}
                            events={events}
                            backgroundEvents={backgroundEvents}
                            resizable={view === Views.MONTH ? false : true}
                            resources={view === Views.WEEK ? null : getResources()}
                            resourceIdAccessor="id"
                            resourceTitleAccessor="name"
                            drilldownView={Views.DAY}
                            // popup
                            showMultiDayTimes
                            selectable
                            timeslots={1}
                            step={60}
                        />
                    </div>
                </div>
            </div>
            <CardFeedback contentDetail={history.location?.pathname || "CalendarList"} />
            { object && (
                <CalendarElementView
                    object={object}
                    history={history}
                    anchorOrigin={object.event && {
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={object.event && {
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    getAnchorPosition={() => {
                        const {
                            id,
                            event
                        } = object
                        if (event) {
                            return {
                                top: event.pageY - 8,
                                left: event.pageX + 16
                            }
                        } else {
                            // https://github.com/jquense/react-big-calendar/issues/618#issuecomment-367293380
                            const el = object?.id ? document.getElementsByClassName(id)?.[0] : null
                            const {
                                height = 0,
                                top = 0,
                                left = 0
                            } = el ? el.getBoundingClientRect() : {}
                            return {
                                top: top + height,
                                left: left
                            }
                        }
                    }}
                    onClose={() => setObject(null)}
                />
            )}
            { showAgendaTour && (
                <Reactour
                    steps={[
                        {
                            selector: isMobile ? ".filter-mobile .filter #vsfilter-select" : "#agenda-view",
                            position: "bottom",
                            content: (
                                <div id="calendar-tour" className="tour tour-bottom">
                                    <div className="tour-title">Nova atualização</div>
                                    Veja a Lista de Eventos da Agenda, onde você pode visualizar de uma forma mais fácil os agendamentos do seu mês.
                                    <div className="tour-action" onClick={() => removeAgendaViewTour()}>
                                        Fechar
                                    </div>
                                </div>
                            ),
                            action: (node) => {
                                const el = document.getElementsByClassName("reactour__mask--disable-interaction")[0]
                                if(!el){
                                    return
                                }
                                el.style.cursor = "pointer"
                                el.onclick = () => {
                                    isMobile ? selectViewRef.current.onOpen(true) : setSelectViewOpen(true)
                                    removeAgendaViewTour()
                                }
                            }
                        }
                    ]}
                    isOpen={showAgendaTour}
                    showNavigation={false}
                    showCloseButton={false}
                    showButtons={false}
                    showNumber={false}
                    onRequestClose={() => removeAgendaViewTour()}
                    onAfterOpen={_ => (document.body.style.overflowY = 'hidden')}
                    onBeforeClose={_ => (document.body.style.overflowY = 'auto')}
                    disableInteraction={true}
                    maskSpace={3}
                    className="reactour"
                />
            )}
            <Popover
                id="calendar-tip"
                open={showTip}
                anchorEl={tipRef?.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={() => setShowTip(false)}
            >
                <div className="vsbox">
                    <div className="vsbox-body mb-0">
                        <div className="row align-items-center">
                            <div className="col vsbox-ux-title">
                                Legenda
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-auto pe-0">
                                <div className="calendar-bullet" style={{
                                    border: '1px solid #00C4B4',
                                    backgroundColor: '#00C4B4',
                                }}></div>
                            </div>
                            <div className="col">
                                <div className="fw-bold mb-1">Confirmado</div>
                                São agendamentos que foram confirmados pela clínica
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-auto pe-0">
                                <div className="calendar-bullet" style={{
                                    border: '1px solid #00C4B4',
                                    backgroundColor: '#FFFFFF',
                                }}></div>
                            </div>
                            <div className="col">
                                <div className="fw-bold mb-1">Aberto</div>
                                São agendamentos que efetivamente ainda não ocorreram
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-auto pe-0">
                                <div className="calendar-bullet" style={{
                                    border: '1px solid #00C4B4',
                                    backgroundColor: '#00C4B440',
                                }}></div>
                            </div>
                            <div className="col">
                                <div className="fw-bold mb-1">Executado</div>
                                São agendamentos que efetivamente já ocorreram
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-auto pe-0">
                                <div className="calendar-bullet" style={{
                                    border: '1px solid #C2FFFA',
                                    backgroundColor: '#C2FFFA',
                                }}></div>
                            </div>
                            <div className="col">
                                <div className="fw-bold mb-1">Não confirmado</div>
                                São agendamentos que não foram confirmados pelo tutor 
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-auto pe-0">
                                <div className="calendar-bullet" style={{
                                    border: '1px solid #C40000',
                                    backgroundColor: '#C40000',
                                }}></div>
                            </div>
                            <div className="col">
                                <div className="fw-bold mb-1">Cancelado</div>
                                São agendamentos que foram cancelados pela clínica
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-auto pe-0">
                                <div className="calendar-bullet" style={{
                                    border: '1px solid #C40000',
                                    backgroundColor: '#C4000040',
                                }}></div>
                            </div>
                            <div className="col">
                                <div className="fw-bold mb-1">Cancelado via WhatsApp</div>
                                São agendamentos que foram cancelados pelo tutor por intermédio do contato de WhatsApp.
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-auto pe-0">
                                <div className="calendar-bullet" style={{
                                    border: '1px solid #0778D4',
                                    backgroundColor: '#0778D4',
                                }}></div>
                            </div>
                            <div className="col">
                                <div className="fw-bold mb-1">Eventos em branco</div>
                                São eventos que não tenham vínculo com um tutor ou animal
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-auto pe-0">
                                <div className="calendar-bullet" style={{
                                    border: '1px solid #FF8A00',
                                    backgroundColor: '#FF8A00',
                                }}></div>
                            </div>
                            <div className="col">
                                <div className="fw-bold mb-1">Bloqueio de horário</div>
                                São eventos para definir bloqueio de horários para os profissionais convidados da clínica
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col">
                                <div className="alert alert-secondary mb-1">
                                    <div className="row">
                                        <div className="col-auto pe-0">
                                            <IconWarningO width="24" height="24" fill="var(--vsprontuario-secondary-color)" />
                                        </div>
                                        <div className="col">
                                            As cores das legendas serão as mesmas da primeira tag informada no cadastro do agendamento, exceto no caso de eventos cancelados, em branco e bloqueios de horário
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Popover>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    }
}

export default connect(mapStateToProps)(CalendarList)
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import { ReactComponent as IconPrimaryEdit } from "../../assets/icon-primary-edit.svg"
import { ReactComponent as IconClose } from "../../assets/icon-close.svg"

import { CircularProgress, MenuItem, TextField } from '@mui/material';
import TextFieldMask from '../textFieldMask/TextFieldMask';

import { BRAND_EMPTY, STATUS_CANCELED, brandOptions, getBrand, getBrandIcon } from '../../pages/pro/Payment';
import { dayjs, mandatoryField } from '../../utils';

import './PaymentCardField.scss'
const PaymentCardField = forwardRef((props, ref) => {

    const {
        features,
        clinic
    } = props

    const [ loading, setLoading ] = useState(false)
    const [ isEditField, setIsEditField ] = useState(false)
    const [ payment, setPayment ] = useState(null)

    const [ brand, setBrand ] = useState(BRAND_EMPTY)

    const [ cardNumber, setCardNumber ] = useState("")
    const [ errorCardNumber, setErrorCardNumber ] = useState(false)
    const [ helperCardNumber, setHelperCardNumber ] = useState(mandatoryField)

    const cardNumberDigits = cardNumber.replace(/[^0-9]/g, "")

    const [ cardName, setCardName ] = useState("")
    const [ errorCardName, setErrorCardName ] = useState(false)
    const [ helperCardName, setHelperCardName ] = useState(mandatoryField)

    const [ cardExpires, setCardExpires ] = useState("")
    const [ errorCardExpires, setErrorCardExpires ] = useState(false)
    const [ helperCardExpires, setHelperCardExpires ] = useState(mandatoryField)

    const cardExpiresDate = dayjs(`01/${cardExpires}`, "DD/MM/YY").endOf("month")

    const [ cardCVC, setCardCVC ] = useState("")
    const [ errorCardCVC, setErrorCardCVC ] = useState(false)
    const [ helperCardCVC, setHelperCardCVC ] = useState(mandatoryField)

    useImperativeHandle(ref, () => ({
        cardData: {
            brand: brand,
            cardNumber: cardNumber,
            cardName: cardName,
            cardExpires: cardExpires,
            cardCVC: cardCVC
        },
        isEditField: isEditField,
        payment: payment,
        validateOnTouch: validateOnTouch,
        validate: validate,
        isValid: isValid,
        fieldsWithErrorText: fieldsWithErrorText
    }))

    const validateOnTouch = () => {
        if (isEditField || brand || cardNumber || cardName || cardExpires || cardCVC) {
            validate()
        }
    }

    const validate = () => {
        let isValid = true

        if (isEditField && cardNumber.length < 13) {
            isValid = false
            setErrorCardNumber(true)
            setHelperCardNumber(`O número do cartão ${cardNumber.length ? "não é válido" : "é obrigatório"}`)
        } else if (isEditField && brand == BRAND_EMPTY) {
            isValid = false
            setErrorCardNumber(true)
            setHelperCardNumber(`Não conseguimos identificar a bandeira do cartão informado, por favor entre em contato com o nosso suporte`)
        } else {
            setErrorCardNumber(false)
            setHelperCardNumber(mandatoryField)
        }

        if (isEditField && cardName.length < 5) {
            isValid = false
            setErrorCardName(true)
            setHelperCardName(`O nome impresso no cartão ${cardName.length ? "não pode ser menor que 5 caracteres" : "é obrigatório"}`)
        } else {
            setErrorCardName(false)
            setHelperCardName(mandatoryField)
        }

        if (isEditField && cardExpires.length < 5) {
            isValid = false
            setErrorCardExpires(true)
            setHelperCardExpires(`A validade do cartão ${cardExpires.length ? "não é válida" : "é obrigatória"}`)
        } else {
            const now = dayjs().endOf("day")

            if (isEditField && cardExpiresDate.diff(now, "month", true) < 0) {
                isValid = false
                setErrorCardExpires(true)
                setHelperCardExpires(`A validade do cartão não pode ser menor que o mês atual`)
            } else if (isEditField && cardExpiresDate.diff(now, "years", true) > 20) {
                isValid = false
                setErrorCardExpires(true)
                setHelperCardExpires(`A validade do cartão não pode ser maior que 20 anos`)
            } else {
                setErrorCardExpires(false)
                setHelperCardExpires(mandatoryField)
            }
        }

        if (isEditField && cardCVC.length < 3) {
            isValid = false
            setErrorCardCVC(true)
            setHelperCardCVC(`O código de segurança do cartão ${cardCVC.length ? "não pode ser menor que 3 caracteres" : "é obrigatório"}`)
        } else {
            setErrorCardCVC(false)
            setHelperCardCVC(mandatoryField)
        }

        return isValid
    }

    const isValid = () => !errorCardNumber && !errorCardName && !errorCardExpires && !errorCardCVC

    const fieldsWithErrorText = () => {
        let errors = []
        if (loading) {
            errors.push("Salvando a contratação")
        }
        if (errorCardNumber) {
            errors.push("Número do cartão de crédito")
        }
        if (errorCardName) {
            errors.push("Nome impresso no cartão")
        }
        if (errorCardExpires) {
            errors.push("Validade do cartão")
        }
        if (errorCardCVC) {
            errors.push("Código de segurança do cartão")
        }
        return errors
    }

    const getSubscriptions = () => {
        return new Promise((resolve, reject) => {
            if (features?.object) {
                setLoading(true)
                Parse.Cloud.run("getPayment", {
                    clinicId: clinic.objectId || clinic.id
                })
                .then(payment => {
                    setLoading(false)
                    setPayment(payment)
                    resolve()
                })
                .catch(error => {
                    setLoading(false)
                    console.error(error)
                    Swal.fire(
                        'Desculpe',
                        'Ocorreu algum erro ao buscar suas assinaturas, tente novamente mais tarde',
                        'error'
                    )
                    reject()
                })
            } else {
                setLoading(false)
                resolve()
            }
        })
    }

    useEffect(() => {
        validateOnTouch()
    }, [ 
        isEditField, brand, cardNumber, cardName, cardExpires, cardCVC
    ])

    useEffect(() => {
        setBrand(getBrand(cardNumberDigits))
    }, [ cardNumber ])

    useEffect(() => {
        getSubscriptions()
    }, [ features ])

    return (
        <div id="payment-card-field" className="p-0 mt-3">
            {loading ? (
                <div className="data-loading pt-3">
                    <CircularProgress />
                </div>
            ) : (
                <div className="vsbox mb-3">
                    <div className="vsbox-body">
                        {!isEditField && payment ? (
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="row">
                                        <div className="col-4 col-md-auto col-lg-4 col-xxl-auto">
                                            { getBrandIcon(payment?.payment_company?.code, { width: 64, height: 38 }) }
                                        </div>
                                        <div className="col-8 col-md col-lg-8 col-xxl">
                                            <label className="vsbox-info">Número do cartão</label>
                                            <div className="vsbox-info-text">
                                                ●●●● ●●●● ●●●● { payment?.card_number_last_four }
                                            </div>
                                        </div>
                                        <div className="col-8 col-md col-lg-8 col-xxl order-4 order-md-3 order-lg-4 order-xxl-3">
                                            <label className="vsbox-info">Nome impresso</label>
                                            <div className="vsbox-info-text">
                                                { payment?.holder_name }  
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-auto col-lg-4 col-xxl-auto order-3 order-md-4 order-lg-3 order-xxl-4">
                                            <label className="vsbox-info">Validade</label>
                                            <div className="vsbox-info-text">
                                                { dayjs(payment?.card_expiration).format("MM/YYYY") }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { payment.status != STATUS_CANCELED && (
                                    <div className="col-auto">
                                        <div className="row align-items-center justify-content-center">
                                            <div className="col-auto w-100">
                                                <button
                                                    className={`vsbox-btn vsbox-btn-dark`}
                                                    onClick={() => {
                                                        setIsEditField(true)
                                                    }}
                                                >
                                                    <IconPrimaryEdit width="24" height="24" fill={"var(--vsprontuario-primary-color-dark)"} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="row g-3">
                                <div className="col-12">
                                    <div className="row justify-content-between align-items-center">
                                        <div className="col">{isEditField ? 'Alterar' : 'Incluir'} dados de pagamento</div>
                                        {isEditField &&
                                        <div className="col-auto">
                                            <button
                                                className={`vsbox-btn vsbox-btn-alert`}
                                                onClick={() => {
                                                    setIsEditField(false)
                                                }}
                                            >
                                                <IconClose fill="#C40000" width="24" height="24" />
                                            </button>
                                        </div>}
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <TextField
                                        placeholder="ex: Mastercard"
                                        label="Bandeira"
                                        value={brand}
                                        onChange={({ target: { value } }) => {
                                            setBrand(value)
                                        }}
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        select
                                    >
                                        { brandOptions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                { option.label }
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="col">
                                    <TextFieldMask
                                        type="tel"
                                        mask={"0000 0000 0000 00000"}
                                        value={cardNumber}
                                        onAccept={(value) => setCardNumber(value)}
                                        placeholder="1234 1234 1234 1234"
                                        label="Número do Cartão"
                                        error={errorCardNumber}
                                        helperText={helperCardNumber}
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </div>
                                <div className="col-12">
                                    <TextField
                                        placeholder="ex: JOAO DA SILVA"
                                        label="Nome Impresso no Cartão"
                                        value={cardName}
                                        onChange={({ target: { value } }) => setCardName(value)}
                                        error={errorCardName}
                                        helperText={helperCardName}
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </div>
                                <div className="col-12 col-lg-6">
                                    <TextFieldMask
                                        type="tel"
                                        mask="00/00"
                                        value={cardExpires}
                                        onAccept={(value) => setCardExpires(value)}
                                        placeholder="00/00"
                                        label="Validade do Cartão"
                                        error={errorCardExpires}
                                        helperText={helperCardExpires}
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </div>
                                <div className="col-12 col-lg-6">
                                    <TextFieldMask
                                        type="tel"
                                        mask="0000"
                                        value={cardCVC}
                                        onAccept={(value) => setCardCVC(value)}
                                        placeholder="000"
                                        label="Código de Segurança"
                                        error={errorCardCVC}
                                        helperText={helperCardCVC}
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
})

export default PaymentCardField
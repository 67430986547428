import React, { useEffect, useState } from "react"
import { connect } from "react-redux"

import { CircularProgress, Table, TableBody, TableCell, TableRow } from "@mui/material"

import { ReactComponent as IconSquareMinus } from "../../assets/icon-square-minus.svg"
import { ReactComponent as IconSquarePlus } from "../../assets/icon-square-plus.svg"
import { ReactComponent as IconArrowLeft } from "../../assets/icon-arrow-left.svg"

import VSAccordion from "../../components/vsAccordion/VSAccordion"
import VSPagination, { showPagination } from "../../components/vsPagination/VSPagination"

import "./HomeData.scss"
const HomeData = (props) => {

    const {
        title,
        expanded,
        loading,
        clinic,
        prepend,
        footer,
        data,
        emptyText,
        rowsPerPage,
        renderItem,
        onChange,
        headerIcon,
        className,
        alwaysShowFooter
    } = props

    const total = data?.count || 0

    const [ open, setOpen ] = useState(expanded || false)
    const [ page, setPage ] = useState(1)

    const update = () => {
        if (clinic && clinic.isLoaded && clinic.object && open) {
            onChange && onChange(page)
        }
    }

    useEffect(() => {
        setOpen(expanded)
    }, [ expanded ])

    useEffect(() => {
        update()
    }, [ clinic, open ])

    const canShowPagination = total > 0 && rowsPerPage > 0 && total > rowsPerPage
    const showFooter = (total > 0 || alwaysShowFooter) && footer

    const items = (rowsPerPage > 0 ? data?.results?.slice((page - 1) * rowsPerPage, page * rowsPerPage) : data?.results) || []

    return (
        <div id="home-data" className={`box box-table ${className}`}>
            <VSAccordion
                expanded={open}
                toggleExpanded={(expanded) => setOpen(expanded)}
                header={(expanded) => {
                    return (
                        <div className="d-block w-100">
                            <div className="row align-items-center box-header">
                                {headerIcon && 
                                <div className="col-auto pe-0">
                                    {headerIcon}
                                </div>}
                                <div className="col pe-0">
                                    <b>{ title }</b>
                                </div>
                                <div className="col-auto text-center me-2">
                                    {/* { expanded ? (
                                        <IconSquareMinus width="24px" height="24px" fill="var(--vsprontuario-primary-color)" />
                                    ) : (
                                        <IconSquarePlus width="24px" height="24px" fill="var(--vsprontuario-primary-color)" />
                                    )} */}
                                    <IconArrowLeft
                                        width="12px"
                                        height="12px"
                                        fill="var(--vsprontuario-primary-color)"
                                        style={{ 
                                            transform: expanded ? 'rotate(90deg)' : 'rotate(270deg)',
                                            transition: 'all .3s'
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                }}
            >
                {prepend}
                <div className="box-info">
                    <Table>
                        <TableBody>
                            { loading ? (
                                <TableRow>
                                    <TableCell
                                        className="text-center"
                                        colSpan={4}
                                    >
                                        <CircularProgress color="primary" />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                total > 0 ? (
                                    items.map((item, index) => {
                                        return renderItem(item, index)
                                    })
                                ) : (
                                    <TableRow>
                                        <TableCell>
                                            <div className="row align-items-center center-flex">
                                                <div className="col-auto pe-0 text-right">
                                                    <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M59.3774 56.3809C70.3514 48.9373 73.8834 34.0785 67.1728 22.3505C60.1672 10.1068 44.6513 5.91184 32.5172 12.9807C20.3831 20.0496 16.2256 35.7055 23.2313 47.9492C29.6608 59.1861 43.2586 63.6435 54.8316 58.836L59.5445 67.0725C59.1945 68.118 59.2748 69.3036 59.864 70.3335L66.8057 82.4653C67.8942 84.3677 70.3074 85.0181 72.1957 83.918C74.084 82.818 74.7323 80.384 73.6438 78.4817L66.7022 66.3499C66.0976 65.2933 65.0845 64.6229 63.9796 64.4241L59.3774 56.3809ZM63.6895 24.3797C69.5892 34.6905 66.0841 47.8645 55.8789 53.8097C45.6737 59.7549 32.6142 56.2307 26.7145 45.9199C20.8149 35.6092 24.3199 22.4351 34.5251 16.4899C44.7303 10.5448 57.7899 14.069 63.6895 24.3797Z" fill="#B9B9B9"/>
                                                        <path d="M59.6088 69.877C58.7866 68.4386 59.2862 66.6059 60.7247 65.7838L62.4361 64.8056C63.8746 63.9834 65.7072 64.483 66.5294 65.9214L74.1843 79.3141C75.0064 80.7526 74.5069 82.5852 73.0684 83.4074L71.357 84.3856C69.9185 85.2077 68.0859 84.7082 67.2637 83.2697L59.6088 69.877Z" fill="#00C4B4"/>
                                                    </svg>
                                                </div>
                                                <div className="col-auto text-wrap">
                                                    { emptyText || "Nenhum registro encontrado" }
                                                </div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )
                            )}
                        </TableBody>
                    </Table>
                    { !loading && (canShowPagination || showFooter) && (
                        <div className="row gx-3 align-items-center">
                            <div className="col-12">
                                <hr/>
                            </div>
                            <div className="col-12">
                                <div className="row gx-3 align-items-center">
                                    { canShowPagination && (
                                        <div className="col">
                                            <VSPagination
                                                align={footer ? "start" : "center"}
                                                currentPage={page}
                                                rowsPerPage={rowsPerPage}
                                                totalRows={total}
                                                onChange={(_currentPage) => {
                                                    setPage(_currentPage)
                                                }}
                                            />
                                        </div>
                                    )}
                                    { footer }
                                </div>
                            </div>
                            {total > 0 &&
                            <div className="show-pagination-home col-12 mt-2">
                                { showPagination(page, rowsPerPage, total) }
                            </div>}
                        </div>
                    )}
                </div>
            </VSAccordion>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic
    };
};
  
export default connect(mapStateToProps)(HomeData);
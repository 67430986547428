import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { CircularProgress } from '@mui/material'

import { ReactComponent as IconArrowLeft } from "../../assets/icon-arrow-left.svg"
import { ReactComponent as IconPrimaryEdit } from "../../assets/icon-primary-edit.svg"
import { ReactComponent as IconPrimaryTrash } from "../../assets/icon-primary-trash.svg"

import './PatientView.scss'
import { addEvent, dayjs, useWindowSize } from '../../utils'
import PatientPhoto from '../../components/patientPhoto/PatientPhoto'
import { castratedDescription, deletePatient, getAgeFromPatient } from './Patient'
import VSAccordionSimple from '../../components/vsAccordion/VSAccordionSimple'
import PatientTabs from './tabs/PatientTabs'
import { ACL } from '../../constants'

const PatientView = (props) => {

    const { id } = useParams()

    const {
        clinic,
        history,
        permissions
    } = props

    const [ patient, setPatient ] = useState(null)
    const [ loading, setLoading ] = useState(true)

    const {
        width
    } = useWindowSize()

    const isBoxSticky = width >= 1140
    const isMobile = width < 768

    const getPatient = () => {
        setLoading(true)
        setPatient(null)
		var query = new Parse.Query("Patient");
		query.equalTo("isDeleted", false);
		query.equalTo("clinic", clinic.object);
		query.include("owner");
        query.get(id)
		.then(_patient => {
            if (_patient) {
                setPatient(_patient)
            }

            setLoading(false)
            addEvent([
                "TourAppointment__viewPatient",
                "TourVaccine__viewPatient",
                "TourPrescription__viewPatient"
            ], {})
        }).catch(error => {
            history.push('/')
            console.error(error)
            setLoading(false)
		})
    }

    useEffect(() => {
        if (clinic && clinic.isLoaded) {
            getPatient()
        }
    }, [ clinic, id ])

    const owner = patient && patient.get("owner")
    const deceased = patient && patient.get("deceased") == 1

    return (
        <div id="patient-view" className={`row ${deceased ? 'deceased' : ''}`}>
            <div className="col-12">
                { loading ? (
                    <div className="row">
                        <div className="col-12">
                            <div className="data-loading">
                                <CircularProgress />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="row">
                        <div className="col-12">
                            <div className="vsbox vsbox-full">
                                <div className="vsbox-head">
                                    <div
                                        className="vsbox-back"
                                        onClick={() => history.push(`/animal`)}
                                    >
                                        <IconArrowLeft width="8px" height="8px" fill={deceased ? "var(--vsprontuario-secondary-color)" : "#DDDDDD"} />
                                        Ir para lista de Animais
                                    </div>
                                </div>
                            </div>
                            <div className={`vsbox vsbox-full ${isBoxSticky ? 'vsbox-sticky' : ''}`}>
                                <div className="vsbox-head">
                                    <div className="row align-items-center g-3">
                                        <div className="col-auto">
                                            <PatientPhoto patient={patient} withBorder />
                                        </div>
                                        <div className="col patient-main px-xl-0">
                                            {/* <div className="vsbox-detail">
                                                
                                            </div> */}
                                            <div className="vsbox-title">
                                                ID { patient.get("patientNumberId") } • { patient.get("name") }
                                                { deceased && <> | <span className="text-danger">ÓBITO</span></>}
                                            </div>
                                            <div className="vsbox-text">
                                                Espécie: { patient.get("specie") || "Não informado" }
                                                { " - "}
                                                Raça: { patient.get("breed") || "Não informado" }
                                                { patient.get("birthdate") && !deceased && " - " }
                                                { !deceased && getAgeFromPatient(patient, true) }
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-auto mt-2 mt-xl-0">
                                            <div className="row g-3">
                                                <div className="col-12 col-md col-xl-auto pe-2">
                                                    <button
                                                        className="vsbox-btn vsbox-btn-link"
                                                        onClick={() => history.push(`/tutor/${owner.id}`)}
                                                    >
                                                        IR PARA TUTOR(A)
                                                    </button>
                                                </div>
                                                { permissions?.check(ACL.PATIENT, ACL.Rule.EDIT, patient) && (
                                                    <div className="col-6 col-md col-xl-auto x-2">
                                                        <button
                                                            className="vsbox-btn vsbox-btn-primary"
                                                            onClick={() => history.push(`/tutor/${owner.id}/animal/${patient.id}`)}
                                                        >
                                                            <IconPrimaryEdit width="24px" height="24px" fill="#FFFFFF" />
                                                            Editar
                                                        </button>
                                                    </div>
                                                )}
                                                { permissions?.check(ACL.PATIENT, ACL.Rule.DELETE, patient) && (
                                                    <div className="col-6 col-md col-xl-auto ps-2">
                                                        <button
                                                            className="vsbox-btn vsbox-btn-alert"
                                                            onClick={() => deletePatient(patient, () => history.push(`/animal`))}
                                                        >
                                                            <IconPrimaryTrash width="24px" height="24px" fill="#FFFFFF" />
                                                            Excluir
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="vsbox vsbox-full">
                                <div className="vsbox-head pb-1">
                                    <div className="vsbox-separator"></div>
                                    <div className="row">
                                        <div className="col-12">
                                            <VSAccordionSimple
                                                color={deceased ? undefined : "#FFFFFF"}
                                                header={(expanded) => (
                                                    `${ expanded ? "Ocultar" : "Ver" } Dados Complementares do Animal`
                                                )}
                                            >
                                                <div className="row g-3 mb-3">
                                                    <div className="col-6 col-md-4 col-xl">
                                                        <label className="vsbox-info">
                                                            Data de Nascimento
                                                        </label>
                                                        <div className="vsbox-info-text">
                                                            { patient.get("birthdate") ? dayjs(patient.get("birthdate")).format("DD/MM/YYYY") : "Não Preenchido" }
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-md-4 col-xl">
                                                        <label className="vsbox-info">
                                                            Sexo
                                                        </label>
                                                        <div className="vsbox-info-text">
                                                            { patient.get("gender") || "Não Preenchido" }
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-md-4 col-xl">
                                                        <label className="vsbox-info">
                                                            Castrado
                                                        </label>
                                                        <div className="vsbox-info-text">
                                                            { castratedDescription[patient.get("castrated")] || "Não Preenchido" }
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-md-4 col-xl">
                                                        <label className="vsbox-info">
                                                            Porte
                                                        </label>
                                                        <div className="vsbox-info-text">
                                                            { patient.get("size") || "Não Preenchido" }
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-md-4 col-xl">
                                                        <label className="vsbox-info">
                                                            Pelagem
                                                        </label>
                                                        <div className="vsbox-info-text">
                                                            { patient.get("pelage") || "Não Preenchido" }
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-md-4 col-xl">
                                                        <label className="vsbox-info">
                                                            Temperamento
                                                        </label>
                                                        <div className="vsbox-info-text">
                                                            { patient.get("temperament") || "Não Preenchido" }
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-md-4 col-xl">
                                                        <label className="vsbox-info">
                                                            RGA
                                                        </label>
                                                        <div className="vsbox-info-text">
                                                            { patient.get("rga") || "Não Preenchido" }
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-md-4 col-xl">
                                                        <label className="vsbox-info">
                                                            Microchip
                                                        </label>
                                                        <div className="vsbox-info-text">
                                                            { patient.get("microchip") || "Não Preenchido" }
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <label className="vsbox-info">
                                                            Outras Informações
                                                        </label>
                                                        <div className="vsbox-info-text pre-line">
                                                            { patient.get("otherInfo") || "Não Preenchido" }
                                                        </div>
                                                    </div>
                                                </div>
                                            </VSAccordionSimple>
                                        </div>
                                    </div>
                                    <div className="vsbox-separator"></div>
                                    <div className="row">
                                        <div className="col-12">
                                            <VSAccordionSimple
                                                color={deceased ? undefined : "#FFFFFF"}
                                                header={(expanded) => (
                                                    `${ expanded ? "Ocultar" : "Ver" } Observações Internas do Animal`
                                                )}
                                            >
                                                <div className="row mb-3">
                                                    <div className="col pre-line">
                                                        <div className="vsbox-info-text">
                                                            { patient.get("notes") || "Não Preenchido" }
                                                        </div>
                                                    </div>
                                                </div>
                                            </VSAccordionSimple>
                                        </div>
                                    </div>
                                    <div className="vsbox-separator"></div>
                                    <div className="row">
                                        <div className="col-12">
                                            <VSAccordionSimple
                                                color={deceased ? undefined : "#FFFFFF"}
                                                expanded={!isMobile}
                                                header={(expanded) => (
                                                    `${ expanded ? "Ocultar" : "Ver" } Informações do(a) Tutor(a)`
                                                )}
                                            >
                                                <div className="row g-3 mb-3">
                                                    <div className="col-12 col-lg-auto">
                                                        <label className="vsbox-info">
                                                            Nome
                                                        </label>
                                                        <div className="vsbox-info-text">
                                                            { patient?.get("owner")?.get("name") }
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-4 col-lg-auto">
                                                        <label className="vsbox-info">
                                                            CPF
                                                        </label>
                                                        <div className="vsbox-info-text">
                                                            { owner.get("cpf") || "Não Preenchido" }
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-md-4 col-lg-auto">
                                                        <label className="vsbox-info">
                                                            Celular
                                                        </label>
                                                        <div className="vsbox-info-text">
                                                            { owner.get("phone") || "Não Preenchido" }
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-md-4 col-lg">
                                                        <label className="vsbox-info">
                                                            Telefone
                                                        </label>
                                                        <div className="vsbox-info-text">
                                                            { owner.get("landlinePhone") || "Não Preenchido" }
                                                        </div>
                                                    </div>
                                                </div>
                                            </VSAccordionSimple>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <PatientTabs
                                patient={patient}
                                history={history}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(PatientView);
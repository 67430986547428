import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import { connect } from "react-redux"

import { dayjs, getTrialEndsAtForProduct, redirectToCheckout, saveOnTracker, saveTrackersProSubscription } from "../../utils"
import { ACL } from "../../constants"
import { PRO_MONTHLY } from "../pro/Payment"
import { setShowingTag } from "../../store/actions"

import { ReactComponent as IconCloseO } from "../../assets/icon-close-o.svg"

import "./VetTrialPro.scss"
const VetTrialPro = (props) => {

    const {
        features,
        clinic,
        permissions,
        history
    } = props

    const location = useLocation()

    const [ hidden, setHidden ] = useState(false)

    const CONTACT_EMAIL = "contato@vetsmart.com.br"

    const period = dayjs().format("YYYY-MM")

    const tag = (
        features?.tag == "trial_active" && !localStorage.getItem(`vspro-trial-tag-${period}`) ? ({
            type: "alert",
            admin: true,
            text: <>Seu período grátis do Prontuário Pro expira em <b>{ dayjs(getTrialEndsAtForProduct(features)).format("DD/MM/YYYY") }</b>. A cobrança será feita automaticamente, caso queira cancelar sua assinatura clique em "Gerenciar <span className="d-none d-md-inline">minha</span> assinatura".</>,
            button: {
                text: <>Gerenciar <span className="d-none d-md-inline">minha</span> assinatura</>,
                onClick: () => {
                    history.push("/configuracao-clinica/plano-e-cobranca", {
                        tag: "Tarja: Encerramento do trial"
                    })
                    saveOnTracker("Clicou", "Tarja", "Encerramento do trial", "Gerenciar minha assinatura")
                }
            },
            close: {
                onClick: () => {
                    localStorage.setItem(`vspro-trial-tag-${period}`, true)
                    setHidden(true)
                }
            }
        }) : features?.tag == "payment_denied" && !localStorage.getItem(`vspro-overdue-tag-${period}`) ? ({
            type: "warning",
            admin: true,
            text: <b>Identificamos um problema no pagamento da assinatura do Prontuário Pro</b>,
            button: {
                text: <>Gerenciar <span className="d-none d-md-inline">minha</span> assinatura</>,
                onClick: () => {
                    history.push("/configuracao-clinica/plano-e-cobranca", {
                        tag: "Tarja: Problema no pagamento"
                    })
                    saveOnTracker("Clicou", "Tarja", "Problema no pagamento", "Gerenciar minha assinatura")
                }
            },
            link: {
                href: `mailto:${CONTACT_EMAIL}?subject=Prontuário Pro: Fale conosco`,
                text: "Fale conosco",
                onClick: () => {
                    saveOnTracker("Clicou", "Tarja", "Problema no pagamento", "Fale conosco")
                }
            },
            close: {
                onClick: () => {
                    localStorage.setItem(`vspro-overdue-tag-${period}`, true)
                    setHidden(true)
                }
            }
        }) : features?.tag == "subscription_canceled" && !localStorage.getItem(`vspro-cancel-tag-${period}`) ? ({
            type: "danger",
            admin: true,
            text: <>Reative sua assinatura assinando mensalmente ou anualmente com <b>condições especiais</b> de preço e pagamento. Se ficou com alguma dúvida, <a href={`mailto:${CONTACT_EMAIL}?subject=Prontuário Pro: Fale conosco`} target="_blank" className="d-inline p-0">fale conosco</a>.</>,
            button: {
                text: "Assinar anual",
                onClick: () => {
                    saveTrackersProSubscription(features)
                    redirectToCheckout(history)
                    saveOnTracker("Clicou", "Tarja", "Assinatura cancelada", "Assinar anual")
                }
            },
            link: {
                text: "Assinar mensal",
                onClick: () => {
                    redirectToCheckout(history, "prontuario_pro", "monthly")
                    saveOnTracker("Clicou", "Tarja", "Assinatura cancelada", "Assinar mensal")
                }
            },
            close: {
                onClick: () => {
                    localStorage.setItem(`vspro-cancel-tag-${period}`, true)
                    setHidden(true)
                }
            }
        }) : features?.tag == "subscription_monthly" && !localStorage.getItem(`vspro-yearly-tag-${period}`) ? ({
            type: "success",
            admin: true,
            text: <>Você assinou o plano mensal do Prontuário Pro. Fale conosco e assine o <b>plano anual com condições especiais</b>.</>,
            link: {
                href: `mailto:${CONTACT_EMAIL}?subject=Prontuário Pro: Fale conosco`,
                text: "Fale conosco",
                onClick: () => {
                    saveOnTracker("Clicou", "Tarja", "Condições especiais", "Fale conosco")
                }
            },
            close: {
                onClick: () => {
                    localStorage.setItem(`vspro-yearly-tag-${period}`, true)
                    setHidden(true)
                }
            }
        }) : null
    )

    const canViewTag = () => {
        return !hidden && tag && ((tag.admin && permissions?.check(ACL.ADMIN, ACL.Rule.VIEW)) || !tag.admin) ? true : false
    }

    useEffect(() => {
        props.dispatch(setShowingTag(canViewTag()))
    }, [ hidden, tag ])

    return canViewTag() ? (
        <div id="trial-pro" className={`trial trial-${tag.type}`}>
            <div className="row align-items-center g-3">
                <div className="col-12 col-md-auto">
                    {tag.icon || <span className="trial-pro bg-secondary">Pro</span> }
                </div>
                <div className="col-12 col-md">
                    { tag.text }
                </div>
                { tag.button && (
                    <div className="col col-md-auto">
                        <a href={tag.button.href} style={tag.button.style} className="bordered-link text-center" target="_blank" onClick={() => tag.button.onClick && tag.button.onClick()}>
                            { tag.button.text }
                        </a>
                    </div>
                )}
                { tag.link && (
                    <div className="col-auto">
                        <a href={tag.link.href} className="text-center" target={tag.link.href ? "_blank" : "_self"} onClick={() => tag.link.onClick && tag.link.onClick()}>
                            { tag.link.text }
                        </a>
                    </div>
                )}
                { tag.close && (
                    <div className="col-auto trial-pro-close">
                        <IconCloseO width={24} height={24} fill="#666666" className="cursor-pointer" onClick={() => tag.close.onClick && tag.close.onClick()} />
                    </div>
                )}
            </div>
        </div>
    ) : <></>
}

const mapStateToProps = state => {
    return {
        features: state.features,
        permissions: state.permissions,
        clinic: state.clinic
    };
};

export default connect(mapStateToProps)(VetTrialPro)
import { createStore } from "redux";
import reducers from "./reducers";

export const parseState = {
    user: {
        isLoaded: false,
        object: null,
        objectId: null,
		fullName: null,
		email: null,
		username: null,
		countryId: null,
		stateId: null,
		cityId: null,
		cityName: null,
		occupationId: null,
		crmv: null,
		crmvStateId: null,
		cpf: null,
		signature: null,
		contactPhone: null,
        registrationMAPA: null
    },
    features: {
        pro: false,
        ai: false,
        object: null,
        objectId: null,
        subscription: null,
        customer: null,
        isLegacy: false,
        upsell: false,
        upsellPro: false,
        upsellAi: false,
        access: [],
        available: [],
        products: [],
        plan: null,
        planCode: null,
        tag: null
    },
    showingTag: false,
    permissions: null,
    clinic: {
        isLoaded: false,
        object: null,
        objectId: null,
        name: null,
        displayStore: false,
        notes: null,
        createdBy: null,
        owner: null,
        isOwner: false,
        city: null,
        ACL: null,
        trialUsed: false,
        useMessageWithoutDiscount: false,
        store: {
            url: null,
            name: null
        }
    },
    onboarding: {
        object: null,
        hasCreatedOwner: false,
        hasCreatedPatient: false,
        hasCreatedAppointment: false,
        hasCreatedVaccine: false,
        hasSetupClinicPrescription: false,
        hasCreatedPrescription: false,
        hasCreatedPrescriptionShare: false,
        hasKnowMoreAboutDigitalSignature: false,
        hasCompleted: false,
        hasOwner: 0,
        hasPatient: 0,
        hasAppointment: 0,
        hasVaccine: 0,
        hasPrescription: 0,
        lastPrescription: 0,
        currentStep: 0,
        progressStep: 100
    }
}

function configureStore(state = parseState) {
    return createStore(reducers, state);
}

export default configureStore;
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useParams } from "react-router-dom"
import axios from "axios";

import { Checkbox, FormControlLabel, SwipeableDrawer } from '@mui/material'
import { CircularProgress } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';

import { addEvent, colors, getAddressByZipCode, getStateList, mandatoryField, optionalField, saveOnTracker, setClinic, stringifyArray, userToObject, validateCNPJ, validateEmail } from "../../utils";
import { ACL, APP_DEVICE, APP_NAME, APP_VERSION } from "../../constants";
import { getSelectedClinicUser } from "../../store/actions";
import { getClinicUser } from "../vet/Vet";
import { createClinic } from "./Clinic";
import { MenuItem } from "@mui/material";

import { ReactComponent as IconAdd } from "../../assets/icon-add.svg"
import { ReactComponent as IconArrowRight } from "../../assets/icon-arrow-right.svg"
import { ReactComponent as PrescriptionTheme1 } from "../../assets/prescription-theme1.svg"
import { ReactComponent as PrescriptionTheme2 } from "../../assets/prescription-theme2.svg"
import { ReactComponent as PrescriptionTheme3 } from "../../assets/prescription-theme3.svg"

import DisplayStoreField from "./fields/DisplayStoreField";
import AppointmentInfo from "./AppointmentInfo";
import TextFieldMask from "../../components/textFieldMask/TextFieldMask";
import BoxDropdown from "../../components/boxDropdown/BoxDropdown";
import ColorPicker from "../../components/colorPicker/ColorPicker";
import ImageUpload from "../../components/imageUpload/ImageUpload";
import VSPage from "../../components/vsPage/VSPage";

let updatePreviewCheck = 0

import "./ClinicForm.scss"
const ClinicForm = (props) => {
    const {
        nova
    } = useParams()

    const {
        user,
        clinic,
        history,
        permissions
    } = props

    const defaultDisplay = {
        prescription: true,
        examRequest: true,
        term: true,
        vaccine: true
    }

    const [ loading, setLoading ] = useState(false)

    const [ seePreviewMobile, setSeePreviewMobile ] = useState(false)

    const [ previewType, setPreviewType ] = useState(1)
    const [ preview, setPreview ] = useState(null)
    const [ loadingPreview, setLoadingPreview ] = useState(false)

    const [ name, setName ] = useState("")
    const [ errorName, setErrorName ] = useState(false)
    const [ helperName, setHelperName ] = useState(mandatoryField)

    const [ crmv, setCrmv ] = useState("")
    const [ errorCrmv, setErrorCrmv ] = useState(false)
    const [ helperCrmv, setHelperCrmv ] = useState(mandatoryField)

    const [ crmvStateId, setCrmvStateId ] = useState("")
    const [ errorCrmvStateId, setErrorCrmvStateId ] = useState(false)
    const [ helperCrmvStateId, setHelperCrmvStateId ] = useState(mandatoryField)

    const [ companyName, setCompanyName ] = useState("")
    const [ errorCompanyName, setErrorCompanyName ] = useState(false)
    const [ helperCompanyName, setHelperCompanyName ] = useState(optionalField)

    const [ cnpj, setCnpj ] = useState("")
    const [ errorCnpj, setErrorCnpj ] = useState(false)
    const [ helperCnpj, setHelperCnpj ] = useState(optionalField)

    const [ registrationMAPA, setRegistrationMAPA ] = useState("")

    const [ municipalRegistrationId, setMunicipalRegistrationId ] = useState("")

    const [ stateRegistrationId, setStateRegistrationId ] = useState("")

    const [ color, setColor ] = useState(colors[0])
    const [ printTemplate, setPrintTemplate ] = useState("10")

    const [ logo, setLogo ] = useState(null)
    const [ displayLogoIn, setDisplayLogoIn ] = useState(defaultDisplay)

    const [ notes, setNotes ] = useState("")
    const [ displayNotesIn, setDisplayNotesIn ] = useState(defaultDisplay)

    const [ displayStore, setDisplayStore ] = useState(false)
    const [ displayAppointment, setDisplayAppointment ] = useState(true)

    const [ phone, setPhone ] = useState("")
    const [ errorPhone, setErrorPhone ] = useState(false)
    const [ helperPhone, setHelperPhone ] = useState(optionalField)

    const [ email, setEmail ] = useState("")
    const [ errorEmail, setErrorEmail ] = useState(false)
    const [ helperEmail, setHelperEmail ] = useState(optionalField)

    const [ site, setSite ] = useState("")
    const [ facebook, setFacebook ] = useState("")
    const [ instagram, setInstagram ] = useState("")
    const [ twitter, setTwitter ] = useState("")

    const [ loadingZipCode, setLoadingZipCode ] = useState(false)
    const [ zipCode, setZipCode ] = useState("")
    const [ errorZipCode, setErrorZipCode ] = useState(false)
    const [ helperZipCode, setHelperZipCode ] = useState(optionalField)

    const [ addressStreet, setAddressStreet ] = useState("")
    const [ errorAddressStreet, setErrorAddressStreet ] = useState(false)
    const [ helperAddressStreet, setHelperAddressStreet ] = useState(optionalField)

    const [ addressNumber, setAddressNumber ] = useState("")
    const [ addressComplement, setAddressComplement ] = useState("")
    const [ addressReference, setAddressReference ] = useState("")
    const [ neighborhood, setNeighborhood ] = useState("")
    const [ errorNeighborhood, setErrorNeighborhood ] = useState(false)
    const [ helperNeighborhood, setHelperNeighborhood ] = useState(optionalField)

    const [ cityId, setCityId ] = useState("")
    const [ city, setCity ] = useState("")
    const [ errorCity, setErrorCity ] = useState(false)
    const [ helperCity, setHelperCity ] = useState(optionalField)

    const [ stateId, setStateId ] = useState("")

    const findPreview = () => {
        if (clinic && clinic.object) {
            setLoadingPreview(true)
            setPreview(null)

            axios.post(
                `${process.env.REACT_APP_PRESCRIPTION}/preview`, 
                {
                    user: userToObject(user.object),
                    clinic: {
                        addressComplement: addressComplement,
                        addressNumber: addressNumber,
                        addressReference: addressReference,
                        addressStreet: addressStreet,
                        city: city,
                        cityId: null,
                        cnpj: cnpj,
                        color: color,
                        companyName: companyName,
                        crmv: crmv,
                        crmvStateId: crmvStateId,
                        displayAppointment: displayAppointment,
                        displayLogoIn: displayLogoIn,
                        displayNotesIn: displayNotesIn,
                        displayStore: displayStore,
                        email: email,
                        facebook: facebook,
                        instagram: instagram,
                        isDeleted: false,
                        isHomeCare: false,
                        isPhysicalClinic: false,
                        logo: logo && logo._url,
                        municipalRegistrationId: municipalRegistrationId,
                        name: name,
                        neighborhood: neighborhood,
                        notes: notes,
                        objectId: null,
                        phone: phone,
                        printTemplate: printTemplate,
                        registrationMAPA: registrationMAPA,
                        site: site,
                        stateId: stateId,
                        stateRegistrationId: stateRegistrationId,
                        twitter: twitter,
                        zipCode: zipCode
                    }
                }
            )
            .then(response => {
                setPreview(response.data)
                setLoadingPreview(false)
            })
            .catch(error => {
                console.error(error)
            })
        }
    }

    const findAddress = () => {
        setLoadingZipCode(true)
        getAddressByZipCode(zipCode)
        .then(data => {
            setLoadingZipCode(false)
            setAddressStreet(data.address)
            setNeighborhood(data.neighborhood)
            setCity(data.city)
            setCityId(data.cityId)
            setStateId(data.state_id)
        })
        .catch(_ => {
            setLoadingZipCode(false)
        })
        validateOnTouch()
    }

    const validateOnTouch = () => {
        if (name || crmv || crmvStateId || companyName || cnpj || registrationMAPA || municipalRegistrationId || stateRegistrationId || notes || phone || email || zipCode || addressStreet || neighborhood || city) {
            validate()
        }
    }

    const validate = () => {
        let isValid = true
        if (loading) {
            isValid = false
        }

        if (!name) {
            isValid = false
            setErrorName(true)
            setHelperName("O nome da clínica é obrigatório")
        } else if (name.length < 3) {
            isValid = false
            setErrorName(true)
            setHelperName("O nome da clínica não pode ser menor que 3 caracteres")
        } else {
            setErrorName(false)
            setHelperName(mandatoryField)
        }

        if (!crmv) {
            isValid = false
            setErrorCrmv(true)
            setHelperCrmv("O CRMV da clínica é obrigatório")
        } else if (crmv.length < 3) {
            isValid = false
            setErrorCrmv(true)
            setHelperCrmv("O CRMV da clínica não pode ser menor que 3 caracteres")
        } else {
            setErrorCrmv(false)
            setHelperCrmv(mandatoryField)
        }

        if (!crmvStateId) {
            isValid = false
            setErrorCrmvStateId(true)
            setHelperCrmvStateId("O estado do CRMV da clínica é obrigatório")
        } else {
            setErrorCrmvStateId(false)
            setHelperCrmvStateId(mandatoryField)
        }

        if (!companyName || companyName.length >= 3) {
            setErrorCompanyName(false)
            setHelperCompanyName(optionalField)
        } else {
            isValid = false
            setErrorCompanyName(true)
            setHelperCompanyName("A razão social não pode ser menor que 3 caracteres")
        }

        let unmaskedCnpj = cnpj && cnpj.replace(/[^0-9]/g, '')
        if (!unmaskedCnpj || validateCNPJ(unmaskedCnpj)) {
            setErrorCnpj(false)
            setHelperCnpj(optionalField)
        } else {
            isValid = false
            setErrorCnpj(true)
            setHelperCnpj("O CNPJ informado não é válido")
        }

        let unmaskedPhone = phone && phone.replace(/[^0-9]/g, '')
        if(unmaskedPhone.length == 0 || unmaskedPhone.length == 10 || unmaskedPhone.length == 11){
            setErrorPhone(false)
            setHelperPhone(optionalField)
        } else {
            isValid = false
            setErrorPhone(true)
            setHelperPhone("O número de telefone informado não é válido")
        }

        if(email.length == 0 || validateEmail(email)) {
            setErrorEmail(false)
            setHelperEmail(optionalField)
        } else {
            isValid = false
            setErrorEmail(true)
            setHelperEmail("O e-mail informado não é válido")
        }

        if(zipCode.length > 0 && zipCode.length < 9){
            isValid = false
            setErrorZipCode(true)
            setHelperZipCode("O CEP da clínica não é válido")
        } else  {
            setErrorZipCode(false)
            setHelperZipCode(optionalField)
        }

        if(addressStreet.length > 0 && addressStreet.length < 3){
            isValid = false
            setErrorAddressStreet(true)
            setHelperAddressStreet("O endereço não pode ser menor que 3 caracteres")
        } else {
            setErrorAddressStreet(false)
            setHelperAddressStreet(optionalField)
        }

        if(neighborhood.length > 0 && neighborhood.length < 3){
            isValid = false
            setErrorNeighborhood(true)
            setHelperNeighborhood("O bairro não pode ser menor que 3 caracteres")
        } else {
            setErrorNeighborhood(false)
            setHelperNeighborhood(optionalField)
        }

        if(city.length > 0 && city.length < 3){
            isValid = false
            setErrorCity(true)
            setHelperCity("A cidade não pode ser menor que 3 caracteres")
        } else {
            setErrorCity(false)
            setHelperCity(optionalField)
        }

        return isValid
    }

    const isValid = () => !loading && !errorName && !errorCrmv && !errorCrmvStateId && !errorCnpj && !errorPhone && !errorEmail && !errorZipCode && !errorAddressStreet && !errorNeighborhood && !errorCity && (nova || permissions?.check(ACL.SETTING, ACL.Rule.EDIT, clinic.object))

    const fieldsWithErrorText = () => {
        let errors = []
        if (errorName) {
            errors.push("Nome")
        }
        if (errorCrmv) {
            errors.push("CRMV")
        }
        if (errorCrmvStateId) {
            errors.push("Estado do CRMV")
        }
        if (errorCompanyName) {
            errors.push("Razão social")
        }
        if (errorCnpj) {
            errors.push("CNPJ")
        }
        if (errorPhone) {
            errors.push("Telefone")
        }
        if (errorEmail) {
            errors.push("E-mail")
        }
        if (errorZipCode) {
            errors.push("CEP")
        }
        if (errorAddressStreet) {
            errors.push("Endereço")
        }
        if (errorNeighborhood) {
            errors.push("Bairro")
        }
        if (errorCity) {
            errors.push("Cidade")
        }
        if (loading) {
            errors.push("Salvando a clínica")
        }
        if (!nova && permissions?.check(ACL.SETTING, ACL.Rule.EDIT, clinic.object)) {
            errors.push("Acesso negado")
        }
        return errors
    }

    const save = () => {
        if (validate()) {
            setLoading(true)

            const saveUser = Parse.User.current()

            if (nova) {
                const params =  {
                    "user": saveUser.id,
                    "createdApp": APP_NAME,
                    "createdAppVersion": APP_VERSION,
                    "createdDevice": APP_DEVICE,
                    "withSampleData": false,
                    "clinic": {
                        'addressComplement': addressComplement,
                        'addressNumber': addressNumber,
                        'addressReference': addressReference,
                        'addressStreet': addressStreet,
                        'city': city,
                        'cityId': cityId,
                        'cnpj': cnpj,
                        'color': color,
                        'companyName': companyName,
                        'crmv': crmv,
                        'crmvStateId': crmvStateId,
                        'displayAppointment': displayAppointment,
                        'displayLogoIn': displayLogoIn,
                        'displayNotesIn': displayNotesIn,
                        'displayStore': displayStore,
                        'email': email,
                        'facebook': facebook,
                        'instagram': instagram,
                        'logo': logo,
                        'municipalRegistrationId': municipalRegistrationId,
                        'name': name,
                        'neighborhood': neighborhood,
                        'notes': notes,
                        'phone': phone,
                        'printTemplate': printTemplate,
                        'registrationMAPA': registrationMAPA,
                        'site': site,
                        'stateId': stateId,
                        'stateRegistrationId': stateRegistrationId,
                        'twitter': twitter,
                        'zipCode': zipCode
                    }
                }

                createClinic(params)
                .then(_clinic => {
                    afterSave(_clinic)
                })
                .catch(message => {
                    Swal.fire(
                        'Oops...',
                        message,
                        'error'
                    )
                    setLoading(false)
                })
            } else {
                let saveClinic = clinic.object
                saveClinic.set("addressComplement", addressComplement);
                saveClinic.set("addressNumber", addressNumber);
                saveClinic.set("addressReference", addressReference);
                saveClinic.set("addressStreet", addressStreet);
                saveClinic.set("changedApp", APP_NAME)
                saveClinic.set("changedAppVersion", APP_VERSION)
                saveClinic.set("changedAt", new Date())
                saveClinic.set("changedBy", saveUser)
                saveClinic.set("changedDevice", APP_DEVICE)
                saveClinic.set("city", city);
                saveClinic.set("cityId", cityId);
                saveClinic.set("cnpj", cnpj);
                saveClinic.set("color", color);
                saveClinic.set("companyName", companyName);
                saveClinic.set("crmv", crmv);
                saveClinic.set("crmvStateId", crmvStateId);
                saveClinic.set("displayAppointment", displayAppointment);
                saveClinic.set("displayLogoIn", displayLogoIn);
                saveClinic.set("displayNotesIn", displayNotesIn);
                saveClinic.set("displayStore", displayStore);
                saveClinic.set("email", email);
                saveClinic.set("facebook", facebook);
                saveClinic.set("instagram", instagram);
                saveClinic.set("logo", logo);
                saveClinic.set("municipalRegistrationId", municipalRegistrationId);
                saveClinic.set("name", name);
                saveClinic.set("neighborhood", neighborhood);
                saveClinic.set("notes", notes);
                saveClinic.set("phone", phone);
                saveClinic.set("printTemplate", printTemplate);
                saveClinic.set("registrationMAPA", registrationMAPA);
                saveClinic.set("site", site);
                saveClinic.set("stateId", stateId);
                saveClinic.set("stateRegistrationId", stateRegistrationId);
                saveClinic.set("twitter", twitter);
                saveClinic.set("zipCode", zipCode);
                saveClinic.save()
                .then(_clinic => {
                    afterSave(_clinic)
                })
                .catch(error => {
                    console.error(error)
                    Swal.fire(
                        'Oops...',
                        `Não foi possível ${nova ? "cadastrar" : "editar"} a clínica, por favor, tente novamente`,
                        'error'
                    )
                    setLoading(false)
                    saveClinic.revert()
                })
            }

        }
    }

    const afterSave = (_clinic) => {
        getClinicUser(_clinic)
        .then(clinicUser => {
            saveOnTracker("Concluiu", "Clínica", _clinic.id)
            addEvent("TourSettings__onChangeSettings", {})
            setClinic(_clinic)
            props.dispatch(getSelectedClinicUser(clinicUser))
    
            history.push('/')
            setLoading(false)
        })
        .catch(error => {
            console.error(error)
            Swal.fire(
                'Oops...',
                error,
                'error'
            )
            setLoading(false)
        })
    }
    
    const documentPreview = (
        <div className="row g-3 align-items-center">
            <div className="col-12">
                <div className="btn-tabs">
                    <div
                        className={`btn-tab ${previewType == 1 ? 'active' : ''}`}
                        onClick={() => setPreviewType(1)}
                    >
                        Prévia Impressão
                    </div>
                    <div
                        className={`btn-tab ${previewType == 0 ? 'active' : ''}`}
                        onClick={() => setPreviewType(0)}
                    >
                        Prévia Digital
                    </div>
                </div>
            </div>
            <div className="col">
                <div className="preview">
                    { loadingPreview ? (
                        <div style={{ textAlign: "center", padding: '36px' }}>
                            <CircularProgress size={28} thickness={2} color="primary"/>
                        </div>
                    ) : (
                        <div
                            dangerouslySetInnerHTML={{__html: preview}}
                            className={previewType == 0 ? 'document-digital' : 'document-print'}
                        >
                        </div>
                    )}
                </div>
            </div>
        </div>
    )

    useEffect(() => {
        updatePreviewCheck++
        const currentCheck = updatePreviewCheck
        setTimeout(() => {
            if (currentCheck == updatePreviewCheck) {
                findPreview()
            }
        }, 500)
    }, [ name, crmv, crmvStateId, companyName, cnpj, registrationMAPA, municipalRegistrationId, stateRegistrationId, color, printTemplate, logo, displayLogoIn, notes, displayNotesIn, displayStore, displayAppointment, phone, email, site, facebook, instagram, twitter, zipCode, addressStreet, addressNumber, addressComplement, addressReference, neighborhood, city, stateId ])

    useEffect(() => {
        validateOnTouch()
    }, [ name, crmv, crmvStateId, companyName, cnpj, registrationMAPA, municipalRegistrationId, stateRegistrationId, phone, email, addressStreet, neighborhood, city ])

    useEffect(() => {
        findAddress()
    }, [ zipCode ])

    useEffect(() => {
        if (clinic.object && !nova) {
            if (!permissions?.check(ACL.SETTING, ACL.Rule.EDIT, clinic.object)) {
                permissions?.error()
                return
            }

            const selectedClinic = clinic.object
            setName(selectedClinic.get("name") || "")
            setCrmv(selectedClinic.get("crmv") || "")
            setCrmvStateId(selectedClinic.get("crmvStateId") || "")
            setCompanyName(selectedClinic.get("companyName") || "")
            setCnpj(selectedClinic.get("cnpj") || "")
            setRegistrationMAPA(selectedClinic.get("registrationMAPA") || "")
            setMunicipalRegistrationId(selectedClinic.get("municipalRegistrationId") || "")
            setStateRegistrationId(selectedClinic.get("stateRegistrationId") || "")
            setColor(selectedClinic.get("color") || colors[0])
            setPrintTemplate(selectedClinic.get("printTemplate") || "10")
            setLogo(selectedClinic.get("logo"))
            setDisplayLogoIn(Object.assign(defaultDisplay, selectedClinic.get("displayLogoIn") || {}))
            setNotes(selectedClinic.get("notes") || "")
            setDisplayNotesIn(Object.assign(defaultDisplay, selectedClinic.get("displayNotesIn") || {}))
            setDisplayStore(selectedClinic.get("displayStore"))
            setDisplayAppointment(selectedClinic.get("displayAppointment"))
            setPhone(selectedClinic.get("phone") || "")
            setEmail(selectedClinic.get("email") || "")
            setSite(selectedClinic.get("site") || "")
            setFacebook(selectedClinic.get("facebook") || "")
            setInstagram(selectedClinic.get("instagram") || "")
            setTwitter(selectedClinic.get("twitter") || "")
            setZipCode(selectedClinic.get("zipCode") || "")
            setAddressStreet(selectedClinic.get("addressStreet") || "")
            setAddressNumber(selectedClinic.get("addressNumber") || "")
            setAddressComplement(selectedClinic.get("addressComplement") || "")
            setAddressReference(selectedClinic.get("addressReference") || "")
            setNeighborhood(selectedClinic.get("neighborhood") || "")
            setCity(selectedClinic.get("city") || "")
            setCityId(selectedClinic.get("cityId") || "")
            setStateId(selectedClinic.get("stateId") || "")
        }
    }, [ clinic ])

    const errors = fieldsWithErrorText()

    return (
        <div id="clinic">
            <VSPage
                loading={loading}
                title={nova ? "Criar nova clínica" : "Configurações da clínica"}
                cancel="Cancelar"
                onCancel={() => history.go(-1)}
                disabled={!isValid()}
                errors={errors}
                submit={nova ? "Criar clínica" : "Salvar alterações"}
                onSubmit={() => save()}
            >
                <div className="row g-3">
                    <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                        <div className="row g-3 align-items-center">
                            <div className="col-12">
                                <BoxDropdown checked title="Dados cadastrais">
                                    <div className="row">
                                        <div className="col">
                                            <TextField
                                                className="w-100"
                                                placeholder="Nome da clínica"
                                                label="Nome da clínica"
                                                value={name}
                                                onChange={({ target: { value } }) => setName(value)}
                                                error={errorName}
                                                helperText={helperName}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col input-info mb-3">
                                            Esse é o nome que vai aparecer no cabeçalho dos documentos gerados, 
                                            como prescrições, solicitaçoes de exames, termos e atestados.
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <TextField
                                                type="tel"
                                                className="w-100"
                                                placeholder="Informe o CRMV da Clínica"
                                                label="CRMV da clínica"
                                                value={crmv}
                                                onChange={({ target: { value } }) => setCrmv(value ? value.replace(/[^0-9]/g, '') : '')}
                                                error={errorCrmv}
                                                helperText={helperCrmv}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col input-info mb-3">
                                            Caso você não tenha um CRMV para sua Clínica, 
                                            utilize o seu CRMV pessoal
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <TextField
                                                className="w-100"
                                                placeholder="São Paulo"
                                                label="Estado"
                                                value={crmvStateId}
                                                onChange={({ target: { value } }) => setCrmvStateId(value)}
                                                error={errorCrmvStateId}
                                                helperText={helperCrmvStateId}
                                                select
                                            >
                                                { getStateList().map(stateItem => (
                                                    <MenuItem key={stateItem.value} value={stateItem.value}>
                                                        { stateItem.text }
                                                    </MenuItem>
                                                )) }
                                            </TextField>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col input-info mb-3">
                                            Caso você não tenha um CRMV para sua Clínica, 
                                            utilize o seu CRMV pessoal
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col mb-3">
                                            <TextField
                                                className="w-100"
                                                placeholder="Razão social"
                                                label="Razão social"
                                                value={companyName}
                                                onChange={({ target: { value } }) => setCompanyName(value)}
                                                error={errorCompanyName}
                                                helperText={helperCompanyName}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col mb-3">
                                            <TextFieldMask
                                                type="tel"
                                                mask="00.000.000/0000-00"
                                                value={cnpj}
                                                onAccept={(value) => setCnpj(value)}
                                                className="w-100"
                                                placeholder="00.000.000/0000-00"
                                                label="CNPJ"
                                                error={errorCnpj}
                                                helperText={helperCnpj}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col mb-3">
                                            <TextField
                                                placeholder={`Informe o número registro no MAPA`}
                                                label="Nº registro no MAPA da Clínica"
                                                value={registrationMAPA}
                                                onChange={({ target: { value } }) => setRegistrationMAPA(value)}
                                                helperText={<span>Campo Opcional</span>}
                                                fullWidth
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col mb-3">
                                            <TextField
                                                placeholder="Inscrição Municipal"
                                                label="Inscrição Municipal"
                                                value={municipalRegistrationId}
                                                onChange={({ target: { value } }) => setMunicipalRegistrationId(value)}
                                                helperText={<span>Campo Opcional</span>}
                                                fullWidth
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col mb-3">
                                            <TextField
                                                placeholder="Informe a Inscrição Estadual"
                                                label="Inscrição Estadual"
                                                value={stateRegistrationId}
                                                onChange={({ target: { value } }) => setStateRegistrationId(value)}
                                                helperText={<span>Campo Opcional</span>}
                                                fullWidth
                                            />
                                        </div>
                                    </div>
                                </BoxDropdown>
                            </div>
                            <div className="col-12">
                                <BoxDropdown title="Configurações de Documentos">
                                    <h3 className="input-title">
                                        COR PADRÃO (OBRIGATÓRIO)
                                    </h3>
                                    <div className="input-text">
                                        A escolha de uma cor padrão é obrigatória e será utilizada 
                                        nos documentos gerados, como prescrições, solicitações de 
                                        exames, termos e atestados.
                                    </div>
                                    <ColorPicker
                                        colors={colors}
                                        color={color}
                                        onChange={(_color) => setColor(_color)}
                                        className="mb-3"
                                    />
                                    <h3 className="input-title">
                                        TEMA DE DOCUMENTOS IMPRESSOS
                                    </h3>
                                    <div className="input-text">
                                        O Tema escolhido será <b>aplicado para todos os documentos 
                                        gerados no prontuário</b> como Prescrições, Solicitações de 
                                        Exames, Atestados e Termos.
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <PrescriptionTheme1
                                                className={`w-100 h-auto prescription-theme ${printTemplate == "10" ? "selected" : ""}`}
                                                onClick={() => setPrintTemplate("10")}
                                            />
                                        </div>
                                        <div className="col">
                                            <PrescriptionTheme2
                                                className={`w-100 h-auto prescription-theme ${printTemplate == "11" ? "selected" : ""}`}
                                                onClick={() => setPrintTemplate("11")}
                                            />
                                        </div>
                                        <div className="col">
                                            <PrescriptionTheme3
                                                className={`w-100 h-auto prescription-theme ${printTemplate == "12" ? "selected" : ""}`}
                                                onClick={() => setPrintTemplate("12")}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <ImageUpload
                                                className="w-100"
                                                getImage={(image) => setLogo(image)}
                                                image={logo}
                                                label="Logo (Opcional)"
                                                text={<span>O Logo é opcional. Caso você não tenha um logo, o <b>Nome da Clínica</b> aparecerá em destaque.</span>}
                                                style={{
                                                    image: {
                                                        width: '148px',
                                                        height: '148px',
                                                        borderRadius: '50%'
                                                    },
                                                    buttons: {
                                                        width: 'calc(100% - 168px)',
                                                        float: 'right',
                                                        height: '148px',
                                                        alignItems: 'center',
                                                        changeButton: {
                                                            paddingLeft: 0
                                                        },
                                                        deleteButton: {
                                                            paddingLeft: 0
                                                        }
                                                    }
                                                }}
                                            >
                                                <IconAdd width="24" height="24" />
                                                Adicionar logo
                                            </ImageUpload>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <div className="checkbox-title mb-2">Aplicar Logo em:</div>
                                            <FormControlLabel
                                                label="Prescrição"
                                                className="d-block mb-0"
                                                control={
                                                    <Checkbox
                                                        checked={displayLogoIn.prescription}
                                                        onChange={({ target: { checked } }) => setDisplayLogoIn({
                                                            ...displayLogoIn,
                                                            prescription: checked
                                                        })}
                                                        color="primary"
                                                        className="p-1"
                                                    />
                                                }
                                            />
                                            <FormControlLabel
                                                label="Solicitações de exame"
                                                className="d-block mb-0"
                                                control={
                                                    <Checkbox
                                                        checked={displayLogoIn.examRequest}
                                                        onChange={({ target: { checked } }) => setDisplayLogoIn({
                                                            ...displayLogoIn,
                                                            examRequest: checked
                                                        })}
                                                        color="primary"
                                                        className="p-1"
                                                    />
                                                }
                                            />
                                            <FormControlLabel
                                                label="Atestados e termos"
                                                className="d-block mb-0"
                                                control={
                                                    <Checkbox
                                                        checked={displayLogoIn.term}
                                                        onChange={({ target: { checked } }) => setDisplayLogoIn({
                                                            ...displayLogoIn,
                                                            term: checked
                                                        })}
                                                        color="primary"
                                                        className="p-1"
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                    <h3 className="input-title">
                                        OBSERVAÇÕES GERAIS
                                    </h3>
                                    <div className="input-text">
                                        As Observações gerais ficarão no rodapé dos seus documentos. 
                                        Aqui você pode colocar informações gerais como regras de 
                                        retorno, horários de atendimento, política de 
                                        internação e visitas, etc.
                                    </div>
                                    <div className="row">
                                        <div className="col mb-3">
                                            <TextField
                                                className="w-100 mb-3"
                                                placeholder="Escreva suas Observações Gerais"
                                                label="Observações Gerais"
                                                value={notes}
                                                onChange={({ target: { value } }) => setNotes(value)}
                                                helperText={optionalField}
                                                multiline
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <div className="checkbox-title mb-2">Aplicar Observações Gerais em:</div>
                                            <FormControlLabel
                                                label="Prescrição"
                                                className="d-block mb-0"
                                                control={
                                                    <Checkbox
                                                        checked={displayNotesIn.prescription}
                                                        onChange={({ target: { checked } }) => setDisplayNotesIn({
                                                            ...displayNotesIn,
                                                            prescription: checked
                                                        })}
                                                        color="primary"
                                                        className="p-1"
                                                    />
                                                }
                                            />
                                            <FormControlLabel
                                                label="Solicitações de exame"
                                                className="d-block mb-0"
                                                control={
                                                    <Checkbox
                                                        checked={displayNotesIn.examRequest}
                                                        onChange={({ target: { checked } }) => setDisplayNotesIn({
                                                            ...displayNotesIn,
                                                            examRequest: checked
                                                        })}
                                                        color="primary"
                                                        className="p-1"
                                                    />
                                                }
                                            />
                                            <FormControlLabel
                                                label="Atestados e termos"
                                                className="d-block mb-0"
                                                control={
                                                    <Checkbox
                                                        checked={displayNotesIn.term}
                                                        onChange={({ target: { checked } }) => setDisplayNotesIn({
                                                            ...displayNotesIn,
                                                            term: checked
                                                        })}
                                                        color="primary"
                                                        className="p-1"
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                </BoxDropdown>
                            </div>
                            <div className="col-12">
                                <BoxDropdown title="Opção de Compra">
                                    <DisplayStoreField
                                        value={displayStore}
                                        onChange={(newValue) => setDisplayStore(newValue)}
                                    />
                                </BoxDropdown>
                            </div>
                            <div className="col-12">
                                <BoxDropdown title="Opções de Compartilhamento">
                                    <div className="input-title mb-2">
                                        <div className="row align-items-center">
                                            <div className="col">
                                                Incluir Consulta
                                            </div>
                                            <div className="col-auto">
                                                <Switch
                                                    checked={displayAppointment}
                                                    onChange={({ target: { checked } }) => setDisplayAppointment(checked)}
                                                    color="primary"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <p className="input-text">
                                        <b>Como funciona:</b> imagine que você registrou uma consulta 
                                        para um animal no dia 20/10 às 17h00 e preencheu o campo de 
                                        Anamnese / Motivo
                                    </p>
                                    <p className="input-text">
                                        Com a opção <b>Incluir Consulta</b> ativada, todas as versões 
                                        digitais das prescrições geradas para esse mesmo animal no 
                                        dia 20/10 a partir das 17h incluirão no rodapé os dados da 
                                        consulta (horário e Anamnese/Motivo)
                                    </p>
                                    <AppointmentInfo />
                                </BoxDropdown>
                            </div>
                            <div className="col-12">
                                <BoxDropdown title="Informações de Contato">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <TextFieldMask
                                                type="tel"
                                                mask={phone && phone.length <= 14 ? '(00) 0000-0000[0]' : '(00) 00000-0000'}
                                                value={phone}
                                                onAccept={(value) => setPhone(value)}
                                                className="w-100"
                                                placeholder="Informe o telefone de contato"
                                                label="Telefone de Contato"
                                                error={errorPhone}
                                                helperText={helperPhone}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col input-info mb-3">
                                            Número de telefone que o tutor pode usar para entrar em 
                                            contato com você ou sua clínica. 
                                            Pode ser telefone fixo ou WhatsApp
                                        </div>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <TextField
                                                type="email"
                                                className="w-100"
                                                placeholder="exemplo@email.com"
                                                label="E-mail"
                                                value={email}
                                                onChange={({ target: { value } }) => setEmail(value)}
                                                error={errorEmail}
                                                helperText={helperEmail}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col input-info mb-3">
                                            E-mail que o tutor pode usar para entrar em 
                                            contato com você ou sua clínica
                                        </div>
                                    </div>
                                </BoxDropdown>
                            </div>
                            <div className="col-12">
                                <BoxDropdown title="Informações de Divulgação">
                                    <div className="row align-items-center">
                                        <div className="col mb-3">
                                            <TextField
                                                type="url"
                                                className="w-100"
                                                placeholder="Informe o site da clínica"
                                                label="Site"
                                                value={site}
                                                onChange={({ target: { value } }) => setSite(value)}
                                                helperText={optionalField}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col mb-3">
                                            <TextField
                                                type="url"
                                                className="w-100"
                                                placeholder="Informe o Facebook da clínica"
                                                label="Facebook"
                                                value={facebook}
                                                onChange={({ target: { value } }) => setFacebook(value)}
                                                helperText={optionalField}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col mb-3">
                                            <TextField
                                                type="url"
                                                className="w-100"
                                                placeholder="Informe o Instagram da clínica"
                                                label="Instagram"
                                                value={instagram}
                                                onChange={({ target: { value } }) => setInstagram(value)}
                                                helperText={optionalField}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <TextField
                                                type="url"
                                                className="w-100"
                                                placeholder="Informe o Twitter da clínica"
                                                label="Twitter"
                                                value={twitter}
                                                onChange={({ target: { value } }) => setTwitter(value)}
                                                helperText={optionalField}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col input-info">
                                            As informações de divulgação são opcionais e 
                                            vão aparecer nos documentos gerados, como 
                                            prescrições, termos e atestados
                                        </div>
                                    </div>
                                </BoxDropdown>
                            </div>
                            <div className="col-12">
                                <BoxDropdown title="Endereço">
                                    <div className="row">
                                        <div className="col input-info mb-3">
                                            O endereço é opcional e é utilizado para a 
                                            emissão de Prescrições de Controle Especial 
                                            e documentos como solicitaçoes de exames, 
                                            termos e atestados
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col">
                                            <TextFieldMask
                                                type="tel"
                                                mask="00000-000"
                                                value={zipCode}
                                                onAccept={(value) => setZipCode(value)}
                                                className="w-100"
                                                placeholder="00000-000"
                                                label="CEP"
                                                error={errorZipCode}
                                                helperText={helperZipCode}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            { loadingZipCode && (
                                                                <CircularProgress size={16} />
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col">
                                            <TextField
                                                className="w-100"
                                                placeholder="Rua, avenida, alameda, etc."
                                                label="Endereço"
                                                value={addressStreet}
                                                onChange={({ target: { value } }) => setAddressStreet(value)}
                                                error={errorAddressStreet}
                                                helperText={helperAddressStreet}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col">
                                            <TextField
                                                className="w-100"
                                                placeholder="000"
                                                label="Número"
                                                value={addressNumber}
                                                onChange={({ target: { value } }) => setAddressNumber(value)}
                                                helperText={optionalField}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col">
                                            <TextField
                                                className="w-100"
                                                placeholder="Informe o complemento"
                                                label="Complemento"
                                                value={addressComplement}
                                                onChange={({ target: { value } }) => setAddressComplement(value)}
                                                helperText={optionalField}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col">
                                            <TextField
                                                className="w-100"
                                                placeholder="Jardim das Flores"
                                                label="Bairro"
                                                value={neighborhood}
                                                onChange={({ target: { value } }) => setNeighborhood(value)}
                                                error={errorNeighborhood}
                                                helperText={helperNeighborhood}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col">
                                            <TextField
                                                className="w-100"
                                                placeholder="São Paulo"
                                                label="Cidade"
                                                value={city}
                                                onChange={({ target: { value } }) => setCity(value)}
                                                error={errorCity}
                                                helperText={helperCity}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col">
                                            <TextField
                                                className="w-100"
                                                placeholder="São Paulo"
                                                label="Estado"
                                                value={stateId}
                                                onChange={({ target: { value } }) => setStateId(value)}
                                                helperText={optionalField}
                                                select
                                            >
                                                { getStateList().map(stateItem => (
                                                    <MenuItem key={stateItem.value} value={stateItem.value}>
                                                        { stateItem.text }
                                                    </MenuItem>
                                                )) }
                                            </TextField>
                                        </div>
                                    </div>
                                </BoxDropdown>
                            </div>
                            <div className="col-12 d-block d-md-none">
                                <button
                                    className="vsbox-btn vsbox-btn-primary"
                                    onClick={() => setSeePreviewMobile(true)}
                                >
                                    Ver prévia
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-7 col-xl-8 d-none d-md-block">
                        { documentPreview }
                    </div>
                </div>
            </VSPage>
            <SwipeableDrawer
                anchor="bottom"
                open={seePreviewMobile}
                className="vsdrawer-bottomsheet"
                onOpen={() => null}
                onClose={() => setSeePreviewMobile(false)}
                PaperProps={{
                    style: {
                        width: "100%"
                    }
                }}
            >
                <div className="vsdrawer-info-header" onClick={() => setSeePreviewMobile(false)}>
                    <div className="row g-3 align-items-center">
                        <div className="col-auto pe-1">
                            <IconArrowRight width={18} height={18} style={{ transform: "rotate(180deg)" }} />
                        </div>
                        <div className="col">
                            Voltar
                        </div>
                    </div>
                </div>
                <div className="vsdrawer-info-body">
                    { documentPreview }
                </div>
            </SwipeableDrawer>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinic: state.clinic,
        permissions: state.permissions
    };
};

export default connect(mapStateToProps)(ClinicForm)
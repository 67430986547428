import React from 'react'

import { ReactComponent as IconWarningO } from "../../assets/icon-warning-o.svg"

import "./SubscriptionAlertView.scss"
const SubscriptionAlertView = (props) => {

    const {
        irreversible = false
    } = props

    return (
        <div id="subscription-alert-view">
            <div className="alert alert-primary mb-1">
                <div className="row">
                    <div className="col-auto pe-0">
                        <IconWarningO width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                    </div>
                    <div className="col">
                        Ao realizar o upgrade de plano, o valor da próxima fatura será ajustado proporcionalmente (pro-rata). Você pagará apenas pelo tempo que utilizou o plano anterior e o restante será cobrado com base no novo plano, garantindo que o pagamento seja justo e sem cobranças duplicadas.
                        { irreversible !== false && (
                            <>
                                <br/>
                                <br/>
                                <b>Importante:</b> Essa migração é irreversível e não haverá como voltar para o plano anterior.
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubscriptionAlertView
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/styles';
import {
    Checkbox,
    FormGroup,
    FormControlLabel, 
    checkboxClasses,
    formControlLabelClasses
} from '@mui/material';
import { ReactComponent as IconCheckboxUnchecked } from '../../assets/icon-checkbox-unchecked.svg'
import { ReactComponent as IconCheckboxChecked } from '../../assets/icon-checkbox-checked.svg'

const VSCheckboxInput = styled(({ className, ...props }) => (
    <Checkbox
        icon={<IconCheckboxUnchecked width="20px" height="20px" />}
        checkedIcon={<IconCheckboxChecked width="20px" height="20px" fill={props.disabled ? "var(--vsprontuario-gray-color-light)" : "var(--vsprontuario-primary-color)"} /> }
        {...props}
        classes={{ root: className }}
    />
))(({ theme }) => ({
    padding: "4px 9px !important",
    color: `${theme.palette.primary.main} !important`,
    '&$checked': {
        color: theme.palette.primary.main,
    },
    checked: {},
}))

const VSCheckboxLabel = styled(({ className, ...props }) => (
    <FormControlLabel {...props} classes={{ root: className }} />
))(({ theme }) => ({
    marginBottom: 0,
    [`& .${formControlLabelClasses.label}`]: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '19px',
        color: 'var(--vsprontuario-gray-color-dark)',
    },
}))

const VSCheckbox = (props) => {

    const {
        name,
        label,
        position,
        onChange,
        className,
        disabled,
        checked
    } = props

    return (
        <FormGroup row className={className}>
            <VSCheckboxLabel
                control={
                    <VSCheckboxInput
                        disabled={disabled}
                        name={name}
                        checked={checked || false}
                        onChange={({ target: { checked } }) => onChange?.(checked)}
                    />
                }
                label={label}
                labelPlacement={position || "end"}
                className="w-100"
            />
        </FormGroup>
    )
}

export default VSCheckbox
import React from 'react'

import { getTrialEndsAtForProduct, redirectToCheckout, saveOnDataLayer, saveOnTracker, saveTrackersProSubscription } from '../../utils';
import { ReactComponent as IconHomeAssistant } from "../../assets/icon-home-assistant.svg"
import { ReactComponent as IconHeaderIA } from "../../assets/icon-header-ia.svg"

const HomeAssistant = (props) => {

    const {
        clinic,
        features,
        history
    } = props

    return (
        <div className="vsbox card-pro-advertising">
            <div className="vsbox-body">
                <div className="row align-items-center mb-2">
                    <div className="col">
                        <div className="vsbox-title mb-1">
                            <IconHeaderIA className="me-0 me-lg-2" width="24px" height="24px" fill="#FF8A00" />
                            Assistente Vetsmart
                        </div>
                    </div>
                    {/* <div className="col-auto">
                        <div className="new-tag">Novo</div>
                    </div> */}
                </div>
                <div className="row g-3">
                    <div className="col-auto">
                        <IconHomeAssistant style={{borderRadius: 10}} />
                    </div>
                    <div className="col">
                        { features?.isLegacy ? (
                            <div className="mb-2">Mantenha o Assistente Vetsmart em sua assinatura PRO e tenha mais agilidade nos seus atendimentos!</div>
                        ) : (
                            <div className="mb-2">{!getTrialEndsAtForProduct(features, 'prontuario_ai') ? "Experimente" : "Assine"} o Assistente Vetsmart e tenha mais agilidade nos seus atendimentos!</div>
                        )}
                        <a 
                            style={{ 
                                cursor: "pointer",
                                fontSize: "14px",
                                fontWeight: 600
                            }} 
                            onClick={() => {
                                if(features?.isLegacy){
                                    saveOnDataLayer({
                                        event_action: `inicio:botao:fazer-upgrade-plano`,
                                        event_label: `prontuario-pro`
                                    })
                                    history.push("/configuracao-clinica/plano-e-cobranca/upgrade")
                                } else {
                                    saveTrackersProSubscription(features)
                                    redirectToCheckout(history, "prontuario_ai")
                                }
                            }}
                        >
                            { features?.isLegacy ? "Fazer o Upgrade de Plano" : 
                            !getTrialEndsAtForProduct(features, 'prontuario_ai') ? "Experimentar o Assistente 15 dias grátis" : 
                            "Assinar o Assistente Vetsmart"}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
  
export default HomeAssistant;
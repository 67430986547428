import React from 'react';
import { useParams } from "react-router-dom";
import { connect } from 'react-redux';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { ACL } from '../../../constants';

import Router from './routers'

import "./PatientTabs.scss"
const PatientTabs = (props) => {

    const {
        tab = "historico"
    } = useParams()

    const {
        features,
        patient,
        history,
        permissions
    } = props

    return (
        <>
            <div id="patient-tabs">
                <Tabs
                    value={tab}
                    textColor="primary"
                    indicatorColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                >
                    <span id="tour-tab-element-position"></span>
                    <Tab
                        label="Histórico"
                        value={"historico"}
                        onClick={() => history.push(`/animal/${patient.id}/historico`)}
                    />
                    { permissions?.check(ACL.WEIGHT, ACL.Rule.VIEW) && (
                        <Tab
                            label="Pesos"
                            value="peso"
                            onClick={() => history.push(`/animal/${patient.id}/peso`)}
                        />
                    )}
                    { permissions?.check(ACL.APPOINTMENT, ACL.Rule.VIEW) && (
                        <Tab
                            id="new-appointment-link"
                            label="Consultas"
                            value="consulta"
                            onClick={() => history.push(`/animal/${patient.id}/consulta`)}
                        />
                    )}
                    { permissions?.check(ACL.VACCINE, ACL.Rule.VIEW) && (
                        <Tab
                            id="new-vaccine-link"
                            label="Vacinas"
                            value="vacina"
                            onClick={() => history.push(`/animal/${patient.id}/vacina`)}
                        />
                    )}
                    { permissions?.check(ACL.PRESCRIPTION, ACL.Rule.VIEW) && (
                        <Tab
                            id="new-prescription-link"
                            label="Prescrições"
                            value="prescricao"
                            onClick={() => history.push(`/animal/${patient.id}/prescricao`)}
                        />
                    )}
                    { permissions?.check(ACL.EXAM_REQUEST, ACL.Rule.VIEW) && (
                        <Tab
                            label="Exames"
                            value="solicitacao"
                            onClick={() => history.push(`/animal/${patient.id}/solicitacao`)}
                        />
                    )}
                    { permissions?.check(ACL.CALENDAR, ACL.Rule.VIEW) && (
                        <Tab
                            label="Agendamentos"
                            value="agenda"
                            className={features?.calendar ? "" : "patient-tab-pro"}
                            onClick={() => history.push(`/animal/${patient.id}/agenda`)}
                        />
                    )}
                    { permissions?.check(ACL.RETURN, ACL.Rule.VIEW) && (
                        <Tab
                            label="Retornos"
                            value="retorno"
                            onClick={() => history.push(`/animal/${patient.id}/retorno`)}
                        />
                    )}
                    { permissions?.check(ACL.TERM, ACL.Rule.VIEW) && (
                        <Tab
                            label="Atestados e Termos"
                            value="atestado_termo"
                            onClick={() => history.push(`/animal/${patient.id}/atestado_termo`)}
                        />
                    )}
                    { permissions?.check(ACL.APPOINTMENT_DRUG, ACL.Rule.VIEW) && (
                        <Tab
                            label="Medicamentos"
                            value="medicamento"
                            onClick={() => history.push(`/animal/${patient.id}/medicamento`)}
                        />
                    )}
                    { permissions?.check(ACL.SURGERY, ACL.Rule.VIEW) && (
                        <Tab
                            label="Cirurgias"
                            value="cirurgia"
                            onClick={() => history.push(`/animal/${patient.id}/cirurgia`)}
                        />
                    )}
                    { permissions?.check(ACL.ATTACHMENT, ACL.Rule.VIEW) && (
                        <Tab
                            label="Anexos"
                            value="anexo"
                            onClick={() => history.push(`/animal/${patient.id}/anexo`)}
                        />
                    )}
                    { permissions?.check(ACL.SALE, ACL.Rule.VIEW) && (
                        <Tab
                            label="Orçamentos"
                            value="orcamento"
                            className={features?.sales ? "" : "patient-tab-pro"}
                            onClick={() => history.push(`/animal/${patient.id}/orcamento`)}
                        />
                    )}
                    { permissions?.check(ACL.SALE, ACL.Rule.VIEW) && ( // needs duplicated because Tabs cannot receive a React.Fragment
                        <Tab
                            label="Vendas"
                            value="venda"
                            className={features?.sales ? "" : "patient-tab-pro"}
                            onClick={() => history.push(`/animal/${patient.id}/venda`)}
                        />
                    )}
                </Tabs>
            </div>
            <div className="patient-content mt-4">
                <Router patient={patient} />
            </div>
        </>
    );
}

const mapStateToProps = state => {
    return {
        features: state.features,
        permissions: state.permissions
    }
}
  
export default connect(mapStateToProps)(PatientTabs)